import store from '@/store';
import requestData from "@/hooks/requestData.js";
import {
  copyArray,
  copyObj,
  isRealNum
} from '@/hooks/tools'
import {
  loginInitializeCheck,
  refreshLoginDate
} from "@/hooks/loginAffair";

const fillDefaultValue = (content, tableName) => {
  const listForValidation = store.state.loginInitializeData.listForValidations
  const list = listForValidation.filter(x => x.tableNameForVali == tableName && x.validateMethod == 'add')
  for (let item of list) {
    for (let key in content) {
      if (item.fieldsNameForVali == key) {
        content[key] = item.defaultValue
      }
    }
  }
}

const getBodyInstance = (tableName) => {
  const listForValidation = store.state.loginInitializeData.listForValidations
  const list = listForValidation.filter(x => x.tableNameForVali == tableName && x.validateMethod == 'add')
  let returnObj = {}
  const length = list.length
  if (length == 0) {
    return {};
  }
  for (let i = length - 1; i >= 0; i--) {
    let fieldName = list[i].fieldsNameForVali
    if (fieldName.substring(fieldName.length - 2, fieldName.length) == 'FS' ||
      fieldName.substring(fieldName.length - 1, fieldName.length) == 'H' ||
      fieldName.substring(fieldName.length - 1, fieldName.length) == 'L') {
      continue;
    }
    returnObj[list[i].fieldsNameForVali] = list[i].defaultValue
  }
  return copyObj(returnObj)
}

const transferToCode = (toBeTransfer, filterName) => {
  if (toBeTransfer) {
    let filter = store.state.loginInitializeData.filterList
    let code = toBeTransfer

    if (filter[filterName].find(x => x.afterTransfer == toBeTransfer)) {
      code = filter[filterName].filter(x => x.afterTransfer == toBeTransfer)[0].beforeTransfer
    }
    return code;
  }
  return '';

}

const transferToName = (toBeTransfer, filterName) => {
  if (toBeTransfer) {
    let filter = store.state.loginInitializeData.filterList
    let name = toBeTransfer
    if (filter[filterName].find(x => x.beforeTransfer == toBeTransfer)) {
      name = filter[filterName].filter(x => x.beforeTransfer == toBeTransfer)[0].afterTransfer
    }
    return name;
  }
  return '';
}

const changeToViewCode = (tableName) => {
  if (tableName) {
    return store.state.loginInitializeData.authList.viewLists.find(x => x.viewId == tableName).viewCode
  }
}

const getDocType = (docHead) => {
  let b = store.state.loginInitializeData.filterList.DocumentTypeId.find(x => x.beforeTransfer == docHead.substring(0, 2).toUpperCase()) ?.afterTransfer
  return b
}


const washBodyCopy = (body) => {
  for (let index in body) {
    body[index]['Sn'] = parseInt(index) + 1
  }
}

const finishDocHeadFroBodyCopy = (body, headName) => {
  for (let index in body) {
    body[index]['DocumentId'] = headName
  }
}

const finishDocHead = (docHead, docBody, headName) => {
  docHead.DocumentId = headName
  docHead.DocumentTypeName = store.state.loginInitializeData.authList.documentTypeManages.find(x => x.documentTitle == docHead.DocumentId.substring(0, 2).toUpperCase()) ?.documentTypeId
  docHead.Amount = (docBody.reduce((ac, cu) => {
    // return ac + parseFloat(cu.Amount) || parseFloat((cu.OrderQty || 1) * (cu.UnitPrice || 0))
    return ac + (parseFloat((cu.OrderQty || 1) * (cu.UnitPrice || 0)) || parseFloat(cu.Amount))
  }, 0)) || ''
  docHead.Qty = (docBody.reduce((ac, cu) => {
    return ac + parseFloat(cu.OrderQty || 1)
  }, 0)) || ''
  docHead.Online = docHead.PartBCompanyId ? parseInt(docHead.PartBCompanyId) < 20000000 : false
}

const washDocHead = function (docHead, arrReturn) {
  const filterA = store.state.loginInitializeData.filterList
  for (let key in docHead) {
    if (key == 'CapitalAccountId') {
      if (!isRealNum(docHead.CapitalAccountId)) {
        arrReturn[key] = filterA.CapitalAccountId.find(x => x.afterTransfer == docHead.CapitalAccountId) ?.beforeTransfer || ''
        continue
      } else {
        arrReturn[key] = docHead[key]
        continue
      }
    }
    if (key == 'InWareHouseId') {
      if (!isRealNum(docHead.InWareHouseId)) {
        arrReturn[key] = filterA.WareHouseId.find(x => x.afterTransfer == docHead.InWareHouseId) ?.beforeTransfer || ''
        continue
      } else {
        arrReturn[key] = docHead[key]
        continue
      }
    }
    if (key == 'OutWareHouseId') {
      if (!isRealNum(docHead.OutWareHouseId)) {
        arrReturn[key] = filterA.WareHouseId.find(x => x.afterTransfer == docHead.OutWareHouseId) ?.beforeTransfer || ''
        continue
      } else {
        arrReturn[key] = docHead[key]
        continue
      }
    }
    if (key == 'PartBCompanyId') {
      if (!isRealNum(docHead.PartBCompanyId)) {
        arrReturn[key] = filterA.BusinessPartnerId.find(x => x.afterTransfer == docHead.PartBCompanyId) ?.beforeTransfer || ''
        continue
      } else {
        arrReturn[key] = docHead[key]
        continue
      }
    }
    if (key == 'StaffId') {
      if (!isRealNum(docHead.StaffId)) {
        arrReturn[key] = filterA.StaffId.find(x => x.afterTransfer == docHead.StaffId) ?.beforeTransfer || ''
        continue
      } else {
        arrReturn[key] = docHead[key]
        continue
      }
    }
    if (key == 'Online') {
      arrReturn[key] = arrReturn.PartBCompanyId > 20000000 ? false : true
    }
    if (key == 'Remark') {
      arrReturn[key] = docHead[key]
    }
    arrReturn[key] = docHead[key]
  }
}

const copyArrayDoc = function (objArr, fieldsName) {
  let newObjArr = [];
  for (let item of objArr) {
    if (item[fieldsName]) {
      let objNew = {};
      for (let vl in item) {
        objNew[vl] = item[vl]
      }
      newObjArr.push(objNew)
    }
  }
  return newObjArr;
};

const getNameList = function (tableName) {
  return copyObj(store.state.loginInitializeData.pageNameManage.filter(x => x.tableName == tableName)[0])
}

const checkDocHead = function (docHead, checkHead) {
  let errMes = '';
  for (let item of checkHead) {
    if (item.mustMaintain) {
      if (docHead[item.fieldsNameForManage] === '') {
        errMes += `\"${item.fieldsShowName}\"未填写\n`
      }
    }
  }
  return errMes;
}

const checkDocBody = function (docBody) {
  let contentCheck = 0;
  for (let content of docBody) {
    if (content.CommodityId != '' || content.CommodityName != '' || content.UnitPrice != '') {
      contentCheck++
    }
  }
  return contentCheck;
}

const getCount = (partbCode) => {
  let config = {
    method: "get",
    url: "/AccountsList/GetCount",
    params: {
      bpCode: partbCode
    }
  }
  return requestData.GetCache(config)
}

const getDocNo = function (docType) {
  let headConfig = {
    method: "get",
    url: "/DocumentSerialNoManage/GetDocNo",
    params: {
      title: docType
    }
  }
  return requestData.GetCache(headConfig)
}

const refreshLoginConfigData = function (funcAfterRefresh) {
  let userInfo = {
    ProductTypeName: store.state.loginInitializeData.productTypeName,
    CompanyNameAbbre: store.state.loginInitializeData.companyNameAbbre,
    UserName: store.state.loginInitializeData.userName,
    Password: store.state.loginInitializeData.password
  }
  const checkResult = loginInitializeCheck(userInfo);
  if (!checkResult) {
    return false;
  }
  refreshLoginDate(checkResult, funcAfterRefresh);
}

const checkBaseData = (companyId) => {

  const config = {
    method: "get",
    url: "/CompanyUserManage/CheckBaseData",
    params: {
      companyId: companyId,
    }
  };
  return requestData.GetData(config)
}



const getLoginData = () => {
  return new Promise((resolve, reject) => {
    let userInfo = {
      ProductTypeName: store.state.loginInitializeData.productTypeName,
      CompanyNameAbbre: store.state.loginInitializeData.companyNameAbbre,
      UserName: store.state.loginInitializeData.userName,
      Password: store.state.loginInitializeData.password
    }
    checkBaseData(store.state.loginInitializeData.userId).then(res => {
      if (res == 0) {
        resolve(0)
      } else {
        const checkResult = loginInitializeCheck(userInfo);
        if (!checkResult) {
          return false;
        }
        requestData.GetDataLong(checkResult).then(res => {
          resolve(res)
        })
      }
    })
  })
}

const checkDocCondition = (docContent, itemHead, tableContent, itemBody) => {
  let errReturn = {
    headErr: '',
    bodyErr: ''
  }
  let headErrMsg = checkDocHead(docContent, itemHead)
  if (headErrMsg) {
    errReturn.headErr = headErrMsg
  }
  let rowsTotal = checkDocBody(tableContent)
  if (!rowsTotal) {
    errReturn.bodyErr = '表单中无数据'
  }
  return errReturn
}


const checkDocConditionPormise = (docContent, itemHead, tableContent, itemBody) => {
  return new Promise(function (resolve, reject) {
    let errReturn = '';
    let headErrMsg = checkDocHead(docContent, itemHead)
    if (headErrMsg) {
      errReturn += headErrMsg + '\n';
    }
    let rowsTotal = checkDocBody(tableContent)
    if (!rowsTotal) {
      errReturn += '表单中无数据'
    }
    if (!errReturn) {
      errReturn = 'pass';
    }
    resolve(errReturn)
  })
}

const deleteDocCenter = (id) => {
  const config = {
    method: "delete",
    url: "/DocumentCenter/Delete",
    params: {
      id: id
    }
  };
  return requestData.GetData(config)
}

const openDocCenter = (docHead, docBody, headName) => {
  finishDocHead(docHead, docBody, headName)
  const config = {
    method: "post",
    url: "/DocumentCenter/Add",
    data: docHead,
  };
  return requestData.GetDataLong(config)
}

const fillContentWithRes = (body, res, index, title) => {

  body[index]['CommodityId'] = res.split('|')[0];
  switch (title) {
    case 'PO':
      body[index]['UnitPrice'] = res.split('|')[4];
      break;
    case 'SO':
      body[index]['UnitPrice'] = res.split('|')[5];
      break;
      case 'RO':
        body[index]['UnitPrice'] = res.split('|')[5];
        break;
    default:
      body[index]['UnitPrice'] = 0;
  }
  body[index]['Unit'] = res.split('|')[2];
  body[index]['CommodityName'] = res.split('|')[1];
  body[index]['OrderQty'] = 1;
  body[index]['Amount'] = (body[index].OrderQty * body[index].UnitPrice).toFixed(2);
  body[index]['VirtualGoods'] = res.split('|')[3] != 'True' ? true : false;

}

const checkCommIds = (ids) => {
  const config = {
    method: "post",
    url: "/CommodityMangeTable/CheckIds",
    data: ids,
  };
  return requestData.GetDataLong(config)
}


const checkInStockId = (wareHouseId) => {
  const config = {
    method: "get",
    url: "/WareHouseList/CheckInstockId",
    params: {
      wareHouseId: wareHouseId
    }
  };
  return requestData.GetData(config)
}

const clearTableContent = (toBeWashedBody, bodySample) => {
  toBeWashedBody.splice(0, toBeWashedBody.length)
  for (let i = 0; i < 20; i++) {
    let b = copyObj(bodySample)
    toBeWashedBody.push(b)
  }
}

const openFrList = (body) => {
  const config = {
    method: "post",
    url: "/FrList/Add",
    data: body,
  };
  return requestData.GetData(config)
}

const openMpList = (body) => {
  const config = {
    method: "post",
    url: "/MpList/Add",
    data: body,
  };
  return requestData.GetData(config)
}

const getVmiCid = () => {
  const config = {
    method: "get",
    url: "/RetailConfigurationManage/GetVmiCid",
  };
  return requestData.GetCache(config)
}

const getArrAttriByCoId = (commodityId, CompanyId) => {
  const config = {
    method: "get",
    url: "/VmiPriceMethodList/GetAttriArr",
    params: {
      commodityId: commodityId,
      CompanyId: CompanyId
    }
  };
  return requestData.GetCache(config)
}

const updateRetailConfig = (data) => {
  const config = {
    method: "post",
    url: "/RetailConfigurationManage/Update",
    data: data,
  };
  return requestData.GetData(config)
}

const openEdList = (body, docCenterBody, docTitle) => {
  return new Promise(function (resolve, reject) {
    let contentBodyCopy = copyArrayDoc(body, 'UnitPrice')
    washBodyCopy(contentBodyCopy)
    let docHeadCopy = {}
    washDocHead(docCenterBody, docHeadCopy)
    openDocCenter(docHeadCopy, contentBodyCopy, docTitle).then(res => {
      if (res != 'pass') {
        alert(res)
        return false;
      }
      for (let item of contentBodyCopy) {
        item.InStockKeeping = false
        item.OutStockKeeping = false
        item.InAccountsKeeping = false
        item.OutAccountsKeeping = true
        item.GoodsProfitKeeping = false
        item.AccountsProfitKeeping = true
        item.InMoneyKeeping = false
        item.OutMoneyKeeping = false
        item.InPriceRecordKeeping = false
        item.OutPriceRecordKeeping = false
        item.OnLineOnTheWayKeeping = false
        item.DeleteOnLineOnTheWayKeeping = false
        item.SelfOnTheWayKeeping = false
        item.DeleteSelfOnTheWayKeeping = false
        item.TobeShippedKeeping = false
        item.DeleteTobeShippedKeeping = false
        item.OnLineAcountConfirmKeeping = false
        item.DeleteOnLineAcountConfirmKeeping = false

      }
      const config = {
        method: "post",
        url: "/EdList/Add",
        data: contentBodyCopy,
      };
      return requestData.GetData(config).then(res => {
        resolve(res)
      })
    })
  })
}

const openErList = (body, docCenterBody, docTitle) => {
  return new Promise(function (resolve, reject) {
    let contentBodyCopy = copyArrayDoc(body, 'UnitPrice')
    washBodyCopy(contentBodyCopy)
    let docHeadCopy = {}
    washDocHead(docCenterBody, docHeadCopy)
    openDocCenter(docHeadCopy, contentBodyCopy, docTitle).then(res => {
      if (res != 'pass') {
        alert(res)
        return false;
      }
      for (let item of contentBodyCopy) {
        item.InStockKeeping = false
        item.OutStockKeeping = false
        item.InAccountsKeeping = true
        item.OutAccountsKeeping = false
        item.GoodsProfitKeeping = false
        item.AccountsProfitKeeping = true
        item.InMoneyKeeping = false
        item.OutMoneyKeeping = false
        item.InPriceRecordKeeping = false
        item.OutPriceRecordKeeping = false
        item.OnLineOnTheWayKeeping = false
        item.DeleteOnLineOnTheWayKeeping = false
        item.SelfOnTheWayKeeping = false
        item.DeleteSelfOnTheWayKeeping = false
        item.TobeShippedKeeping = false
        item.DeleteTobeShippedKeeping = false
        item.OnLineAcountConfirmKeeping = false
        item.DeleteOnLineAcountConfirmKeeping = false

      }
      const config = {
        method: "post",
        url: "/ErList/Add",
        data: contentBodyCopy,
      };
      return requestData.GetData(config).then(res => {
        resolve(res)
      })
    })
  })
}


const openOoList = (body, docCenterBody, docTitle) => {
  return new Promise(function (resolve, reject) {
    let contentBodyCopy = copyArrayDoc(body, 'CommodityName')
    washBodyCopy(contentBodyCopy)
    let docHeadCopy = {}
    washDocHead(docCenterBody, docHeadCopy)
    openDocCenter(docHeadCopy, contentBodyCopy, docTitle).then(res => {
      if (res != 'pass') {
        alert(res)
        return false;
      }
      for (let item of contentBodyCopy) {
        item.InStockKeeping = false
        item.OutStockKeeping = true
        item.InAccountsKeeping = false
        item.OutAccountsKeeping = false
        item.InMoneyKeeping = false
        item.OutMoneyKeeping = false
        item.GoodsProfitKeeping = true
        item.AccountsProfitKeeping = false
        item.InPriceRecordKeeping = false
        item.OutPriceRecordKeeping = false
        item.InStockQty = item.OrderQty
        item.OutStockQty = item.OrderQty
      }
      const config = {
        method: "post",
        url: "/OoList/Add",
        data: contentBodyCopy,
      };
      return requestData.GetData(config).then(res => {
        resolve(res)
      })
    })
  })
}

const openIsList = (body, docCenterBody, docTitle,origin = false) => {
  return new Promise(function (resolve, reject) {
    let contentBodyCopy = copyArrayDoc(body, 'CommodityName')
    washBodyCopy(contentBodyCopy)
    let docHeadCopy = {}
    washDocHead(docCenterBody, docHeadCopy)
    openDocCenter(docHeadCopy, contentBodyCopy, docTitle).then(res => {
      if (res != 'pass') {
        alert(res)
        return false;
      }
      for (let item of contentBodyCopy) {
        item.InStockKeeping = true
        item.OutStockKeeping = false
        item.InAccountsKeeping = false
        item.OutAccountsKeeping = false
        item.InMoneyKeeping = false
        item.OutMoneyKeeping = false
        item.GoodsProfitKeeping = !origin
        item.AccountsProfitKeeping = false
        item.InPriceRecordKeeping = false
        item.OutPriceRecordKeeping = false
        item.InStockQty = item.OrderQty
      }
      const config = {
        method: "post",
        url: "/IsList/Add",
        data: contentBodyCopy,
      };
      return requestData.GetData(config).then(res => {
        resolve(res)
      })
    })
  })


  // finishDocHeadFroBodyCopy(body, headName)
  // for (let item of body) {
  //   item.PartACompanyId = store.state.loginInitializeData.companyId
  // }
  // const config = {
  //   method: "post",
  //   url: "/IsList/Add",
  //   data: body,
  // };
  // return requestData.GetDataLong(config)
}

const openGrList = (body, docCenterBody, docTitle) => {
  return new Promise(function (resolve, reject) {
    let contentBodyCopy = copyArrayDoc(body, 'UnitPrice')
    washBodyCopy(contentBodyCopy)
    let docHeadCopy = {}
    washDocHead(docCenterBody, docHeadCopy)
    openDocCenter(docHeadCopy, contentBodyCopy, docTitle).then(res => {
      if (res != 'pass') {
        alert(res)
        return false;
      }
      for (let item of contentBodyCopy) {
        item.InStockKeeping = false
        item.OutStockKeeping = false
        item.InAccountsKeeping = false
        item.OutAccountsKeeping = true
        item.GoodsProfitKeeping = false
        item.AccountsProfitKeeping = true
        item.InMoneyKeeping = false
        item.OutMoneyKeeping = false
        item.InPriceRecordKeeping = false
        item.OutPriceRecordKeeping = false
        item.OnLineOnTheWayKeeping = false
        item.DeleteOnLineOnTheWayKeeping = false
        item.SelfOnTheWayKeeping = false
        item.DeleteSelfOnTheWayKeeping = false
        item.TobeShippedKeeping = false
        item.DeleteTobeShippedKeeping = false
        item.OnLineAcountConfirmKeeping = false
        item.DeleteOnLineAcountConfirmKeeping = false
      }
      const config = {
        method: "post",
        url: "/GrList/Add",
        data: contentBodyCopy,
      };
      return requestData.GetData(config).then(res => {
        resolve(res)
      })
    })
  })
}

const openGdList = (body, docCenterBody, docTitle) => {
  return new Promise(function (resolve, reject) {
    let contentBodyCopy = copyArrayDoc(body, 'UnitPrice')
    washBodyCopy(contentBodyCopy)
    let docHeadCopy = {}
    washDocHead(docCenterBody, docHeadCopy)
    openDocCenter(docHeadCopy, contentBodyCopy, docTitle).then(res => {
      if (res != 'pass') {
        alert(res)
        return false;
      }
      for (let item of contentBodyCopy) {
        item.InStockKeeping = false
        item.OutStockKeeping = false
        item.InAccountsKeeping = true
        item.OutAccountsKeeping = false
        item.GoodsProfitKeeping = false
        item.AccountsProfitKeeping = true
        item.InMoneyKeeping = false
        item.OutMoneyKeeping = false
        item.InPriceRecordKeeping = false
        item.OutPriceRecordKeeping = false
        item.OnLineOnTheWayKeeping = false
        item.DeleteOnLineOnTheWayKeeping = false
        item.SelfOnTheWayKeeping = false
        item.DeleteSelfOnTheWayKeeping = false
        item.TobeShippedKeeping = false
        item.DeleteTobeShippedKeeping = false
        item.OnLineAcountConfirmKeeping = false
        item.DeleteOnLineAcountConfirmKeeping = false

      }
      const config = {
        method: "post",
        url: "/GdList/Add",
        data: contentBodyCopy,
      };
      return requestData.GetData(config).then(res => {
        resolve(res)
      })
    })
  })
}

const openMiList = (body, docCenterBody, docTitle) => {
  return new Promise(function (resolve, reject) {
    let contentBodyCopy = copyArrayDoc(body, 'UnitPrice')
    washBodyCopy(contentBodyCopy)
    let docHeadCopy = {}
    washDocHead(docCenterBody, docHeadCopy)
    openDocCenter(docHeadCopy, contentBodyCopy, docTitle).then(res => {
      if (res != 'pass') {
        alert(res)
        return false;
      }
      for (let item of contentBodyCopy) {
        item.InStockKeeping = false
        item.OutStockKeeping = false
        item.InAccountsKeeping = false
        item.OutAccountsKeeping = true
        item.GoodsProfitKeeping = false
        item.AccountsProfitKeeping = false
        item.InMoneyKeeping = true
        item.OutMoneyKeeping = false
        item.InPriceRecordKeeping = false
        item.OutPriceRecordKeeping = false
        item.OnLineOnTheWayKeeping = false
        item.DeleteOnLineOnTheWayKeeping = false
        item.SelfOnTheWayKeeping = false
        item.DeleteSelfOnTheWayKeeping = false
        item.TobeShippedKeeping = false
        item.DeleteTobeShippedKeeping = false
        item.OnLineAcountConfirmKeeping = false
        item.DeleteOnLineAcountConfirmKeeping = false
      }

      const config = {
        method: "post",
        url: "/MiList/Add",
        data: contentBodyCopy,
      };
      requestData.GetData(config).then(res => {
        resolve(res)
      })
    })
  })
}

const openEoList = (body, docCenterBody, docTitle) => {
  return new Promise(function (resolve, reject) {
    let contentBodyCopy = copyArrayDoc(body, 'UnitPrice')
    washBodyCopy(contentBodyCopy)
    let docHeadCopy = {}
    washDocHead(docCenterBody, docHeadCopy)
    openDocCenter(docHeadCopy, contentBodyCopy, docTitle).then(res => {
      if (res != 'pass') {
        alert(res)
        return false;
      }
      for (let item of contentBodyCopy) {
        item.InStockKeeping = false
        item.OutStockKeeping = false
        item.InAccountsKeeping = false
        item.OutAccountsKeeping = true
        item.GoodsProfitKeeping = false
        item.AccountsProfitKeeping = true
        item.InMoneyKeeping = false
        item.OutMoneyKeeping = false
        item.InPriceRecordKeeping = false
        item.OutPriceRecordKeeping = false
        item.OnLineOnTheWayKeeping = false
        item.DeleteOnLineOnTheWayKeeping = false
        item.SelfOnTheWayKeeping = false
        item.DeleteSelfOnTheWayKeeping = false
        item.TobeShippedKeeping = false
        item.DeleteTobeShippedKeeping = false
        item.OnLineAcountConfirmKeeping = false
        item.DeleteOnLineAcountConfirmKeeping = false
      }
      const config = {
        method: "post",
        url: "/EoList/Add",
        data: contentBodyCopy,
      };
      requestData.GetData(config).then(res => {
        resolve(res)
      })
    })
  })
}

const openRbList = (body, docCenterBody, docTitle) => {
  return new Promise(function (resolve, reject) {
    let contentBodyCopy = copyArrayDoc(body, 'UnitPrice')
    washBodyCopy(contentBodyCopy)
    let docHeadCopy = {}
    washDocHead(docCenterBody, docHeadCopy)
    openDocCenter(docHeadCopy, contentBodyCopy, docTitle).then(res => {
      if (res != 'pass') {
        alert(res)
        return false;
      }
      for (let item of contentBodyCopy) {
        item.InStockKeeping = false
        item.OutStockKeeping = false
        item.InAccountsKeeping = false
        item.OutAccountsKeeping = true
        item.GoodsProfitKeeping = false
        item.AccountsProfitKeeping = true
        item.InMoneyKeeping = false
        item.OutMoneyKeeping = false
        item.InPriceRecordKeeping = false
        item.OutPriceRecordKeeping = false
        item.OnLineOnTheWayKeeping = false
        item.DeleteOnLineOnTheWayKeeping = false
        item.SelfOnTheWayKeeping = false
        item.DeleteSelfOnTheWayKeeping = false
        item.TobeShippedKeeping = false
        item.DeleteTobeShippedKeeping = false
        item.OnLineAcountConfirmKeeping = false
        item.DeleteOnLineAcountConfirmKeeping = false
      }
      const config = {
        method: "post",
        url: "/RbList/Add",
        data: contentBodyCopy,
      };
      requestData.GetData(config).then(res => {
        resolve(res)
      })
    })
  })
}

const openIoList = (body, docCenterBody, docTitle) => {
  return new Promise(function (resolve, reject) {
    let contentBodyCopy = copyArrayDoc(body, 'UnitPrice')
    washBodyCopy(contentBodyCopy)
    let docHeadCopy = {}
    washDocHead(docCenterBody, docHeadCopy)
    openDocCenter(docHeadCopy, contentBodyCopy, docTitle).then(res => {
      if (res != 'pass') {
        alert(res)
        return false;
      }
      for (let item of contentBodyCopy) {
        item.InStockKeeping = false
        item.OutStockKeeping = false
        item.InAccountsKeeping = true
        item.OutAccountsKeeping = false
        item.GoodsProfitKeeping = false
        item.AccountsProfitKeeping = true
        item.InMoneyKeeping = false
        item.OutMoneyKeeping = false
        item.InPriceRecordKeeping = false
        item.OutPriceRecordKeeping = false
        item.OnLineOnTheWayKeeping = false
        item.DeleteOnLineOnTheWayKeeping = false
        item.SelfOnTheWayKeeping = false
        item.DeleteSelfOnTheWayKeeping = false
        item.TobeShippedKeeping = false
        item.DeleteTobeShippedKeeping = false
        item.OnLineAcountConfirmKeeping = false
        item.DeleteOnLineAcountConfirmKeeping = false
      }
      const config = {
        method: "post",
        url: "/IoList/Add",
        data: contentBodyCopy,
      };
      requestData.GetData(config).then(res => {
        resolve(res)
      })
    })
  })
}

const openMoList = (body, docCenterBody, docTitle) => {
  return new Promise(function (resolve, reject) {
    let contentBodyCopy = copyArrayDoc(body, 'UnitPrice')
    washBodyCopy(contentBodyCopy)
    let docHeadCopy = {}
    washDocHead(docCenterBody, docHeadCopy)
    openDocCenter(docHeadCopy, contentBodyCopy, docTitle).then(res => {
      if (res != 'pass') {
        alert(res)
        return false;
      }
      for (let item of contentBodyCopy) {
        item.InStockKeeping = false
        item.OutStockKeeping = false
        item.InAccountsKeeping = true
        item.OutAccountsKeeping = false
        item.GoodsProfitKeeping = false
        item.AccountsProfitKeeping = false
        item.InMoneyKeeping = false
        item.OutMoneyKeeping = true
        item.InPriceRecordKeeping = false
        item.OutPriceRecordKeeping = false
        item.OnLineOnTheWayKeeping = false
        item.DeleteOnLineOnTheWayKeeping = false
        item.SelfOnTheWayKeeping = false
        item.DeleteSelfOnTheWayKeeping = false
        item.TobeShippedKeeping = false
        item.DeleteTobeShippedKeeping = false
        item.OnLineAcountConfirmKeeping = false
        item.DeleteOnLineAcountConfirmKeeping = false
      }
      const config = {
        method: "post",
        url: "/MoList/Add",
        data: contentBodyCopy,
      };
      requestData.GetData(config).then(res => {
        resolve(res)
      })
    })
  })
}

const closeRepair = (repairRecordCode) => {
  const config = {
    method: "get",
    url: "/RepairRecordList/UpdateRetailInfo",
    params: {
      repairRecordCode: repairRecordCode
    }
  };
  requestData.GetCache(config)
}

const closeRepairPromise = (repairRecordCode) => {
  const config = {
    method: "get",
    url: "/RepairRecordList/UpdateRetailInfo",
    params: {
      repairRecordCode: repairRecordCode
    }
  };
  return requestData.GetData(config)
}

const addSourceStatistics = (data) => {
  const config = {
    method: "post",
    url: "/CustomerSourceStatistics/Add",
    data: data
  };
  requestData.GetCache(config)
}

const addSourceStatisticsPromise = (data) => {
  const config = {
    method: "post",
    url: "/CustomerSourceStatistics/Add",
    data: data
  };
  return requestData.GetData(config)
}

const updateCustomerSourceStatic = (sourceId, settleId, body) => {
  let count = 0;
  let sum = 0;
  for (let item of body) {
    if (item.CommodityName != '') {
      count++
      sum += item.OrderQty * item.UnitPrice
    }
  }
  const config = {
    method: "get",
    url: "/CustomerSourceStatistics/UpdateSettleInfo",
    params: {
      sourceId: sourceId,
      settleId: settleId,
      lines: count,
      sum: sum
    }
  };
  requestData.GetCache(config).then(res => {

  })
}


const updateCustomerSourceStaticPromise = (sourceId, settleId, body) => {
  let count = 0;
  let sum = 0;
  for (let item of body) {
    if (item.CommodityName != '') {
      count++
      sum += item.OrderQty * item.UnitPrice
    }
  }

  const config = {
    method: "get",
    url: "/CustomerSourceStatistics/UpdateSettleInfo",
    params: {
      sourceId: sourceId,
      settleId: settleId,
      lines: count,
      sum: sum
    }
  };
  return requestData.GetData(config)
}

const openStaffKpiStatistics = (body) => {
  const config = {
    method: "post",
    url: "/StaffKpiStatistics/Add",
    data: body
  };
  requestData.GetCache(config).then(res => {

  })
}

const openStaffKpiStatisticsPromise = (body) => {
  const config = {
    method: "post",
    url: "/StaffKpiStatistics/Add",
    data: body
  };
  return requestData.GetData(config)
}

const openObList = (body, docCenterBody, docTitle) => {
  return new Promise(function (resolve, reject) {
    let contentBodyCopy = copyArrayDoc(body, 'CommodityName')
    washBodyCopy(contentBodyCopy)
    let docHeadCopy = {}
    washDocHead(docCenterBody, docHeadCopy)
    openDocCenter(docHeadCopy, contentBodyCopy, docTitle).then(res => {
      if (res != 'pass') {
        alert(res)
        return false;
      }
      for (let item of contentBodyCopy) {
        item.InStockKeeping = false
        item.OutStockKeeping = false
        item.InAccountsKeeping = false
        item.OutAccountsKeeping = false
        item.GoodsProfitKeeping = false
        item.AccountsProfitKeeping = false
        item.InMoneyKeeping = false
        item.OutMoneyKeeping = false
        item.InPriceRecordKeeping = false
        item.OutPriceRecordKeeping = false
        item.OnLineOnTheWayKeeping = false
        item.DeleteOnLineOnTheWayKeeping = false
        item.SelfOnTheWayKeeping = false
        item.DeleteSelfOnTheWayKeeping = false
        item.TobeShippedKeeping = false
        item.DeleteTobeShippedKeeping = false
        item.OnLineAcountConfirmKeeping = false
        item.DeleteOnLineAcountConfirmKeeping = false
      }
      const config = {
        method: "post",
        url: "/ObList/Add",
        data: contentBodyCopy,
      };
      requestData.GetData(config).then(res => {
        resolve(res)
      })
    })
  })
}

const openAsList = (body, docCenterBody, docTitle) => {
  return new Promise(function (resolve, reject) {
    let contentBodyCopy = copyArrayDoc(body, 'CommodityName')
    washBodyCopy(contentBodyCopy)
    let docHeadCopy = {}
    washDocHead(docCenterBody, docHeadCopy)
    openDocCenter(docHeadCopy, contentBodyCopy, docTitle).then(res => {
      if (res != 'pass') {
        alert(res)
        return false;
      }
      for (let item of contentBodyCopy) {
        item.InStockKeeping = false
        item.OutStockKeeping = false
        item.InAccountsKeeping = false
        item.OutAccountsKeeping = false
        item.GoodsProfitKeeping = false
        item.AccountsProfitKeeping = false
        item.InMoneyKeeping = false
        item.OutMoneyKeeping = false
        item.InPriceRecordKeeping = false
        item.OutPriceRecordKeeping = false
        item.OnLineOnTheWayKeeping = false
        item.DeleteOnLineOnTheWayKeeping = false
        item.SelfOnTheWayKeeping = false
        item.DeleteSelfOnTheWayKeeping = false
        item.TobeShippedKeeping = false
        item.DeleteTobeShippedKeeping = false
        item.OnLineAcountConfirmKeeping = false
        item.DeleteOnLineAcountConfirmKeeping = false
      }
      const config = {
        method: "post",
        url: "/AsList/Add",
        data: contentBodyCopy,
      };
      requestData.GetData(config).then(res => {
        resolve(res)
      })
    })
  })
}

const addObTracingObj = (body) => {
  const config = {
    method: "post",
    url: "/ObTracingList/Add",
    data: body,
  };
  return requestData.GetCache(config)
}

const addRemindTime = (code) => {
  const config = {
    method: "get",
    url: "/ObTracingList/UpdateTimes",
    params: {
      id: code
    }
  };
  return requestData.GetData(config)
}

const closeObChasing = (code) => {
  const config = {
    method: "delete",
    url: "/ObTracingList/Delete",
    params: {
      id: code
    }
  };
  return requestData.GetData(config)
}

const PostMachine = (id, price, remark) => {
  const config = {
    method: "get",
    url: "/MachineStorageList/AlterPost",
    params: {
      id: id,
      price: price,
      remark: remark
    }
  };
  return requestData.GetData(config)
}

const unPostMachine = (id) => {
  const config = {
    method: "get",
    url: "/MachineStorageList/UnPostMachine",
    params: {
      id: id
    }
  };
  return requestData.GetData(config)
}

const checkTtCompany = (partBcompanyId, outWareHouseId) => {
  const config = {
    method: "get",
    url: "/WareHouseList/CheckTtCondition",
    params: {
      partBCompnayId: partBcompanyId,
      outWareHouseId: outWareHouseId
    }
  };
  return requestData.GetData(config)
}

const checkTtCondi = (partBCompnayId) => {
  const config = {
    method: "get",
    url: "/WareHouseList/CheckTtConditionStrong",
    params: {
      partBCompnayId: partBCompnayId
    }
  };
  return requestData.GetData(config)
}

const openTtList = (body, docCenterBody, docTitle, sendGoodsCheck = false, OnTheWay = true) => {
  return new Promise(function (resolve, reject) {
    let contentBodyCopy = copyArrayDoc(body, 'CommodityName')
    washBodyCopy(contentBodyCopy)
    let docHeadCopy = {}
    washDocHead(docCenterBody, docHeadCopy)
    openDocCenter(docHeadCopy, contentBodyCopy, docTitle).then(res => {
      if (res != 'pass') {
        alert(res)
        return false;
      }
      for (let item of contentBodyCopy) {
        item.InStockKeeping = false
        item.OutStockKeeping = true
        item.InAccountsKeeping = false
        item.OutAccountsKeeping = false
        item.GoodsProfitKeeping = false
        item.AccountsProfitKeeping = false
        item.InMoneyKeeping = false
        item.OutMoneyKeeping = false
        item.InPriceRecordKeeping = false
        item.OutPriceRecordKeeping = false
        item.OnLineOnTheWayKeeping = OnTheWay
        item.DeleteOnLineOnTheWayKeeping = false
        item.SelfOnTheWayKeeping = false
        item.DeleteSelfOnTheWayKeeping = false
        item.TobeShippedKeeping = sendGoodsCheck
        item.DeleteTobeShippedKeeping = false
        item.OnLineAcountConfirmKeeping = false
        item.DeleteOnLineAcountConfirmKeeping = false
      }
      const config = {
        method: "post",
        url: "/TtList/Add",
        data: contentBodyCopy,
      };
      requestData.GetData(config).then(res => {
        resolve(res)
      })
    })
  })
}

const openPoList = (body, docCenterBody, docTitle, priceKeep = true) => {
  return new Promise(function (resolve, reject) {
    let contentBodyCopy = copyArrayDoc(body, 'CommodityName')
    washBodyCopy(contentBodyCopy)
    let docHeadCopy = {}
    washDocHead(docCenterBody, docHeadCopy)
    openDocCenter(docHeadCopy, contentBodyCopy, docTitle).then(res => {
      if (res != 'pass') {
        alert(res)
        return false;
      }
      for (let item of contentBodyCopy) {
        item.InStockKeeping = true
        item.OutStockKeeping = false
        item.InAccountsKeeping = false
        item.OutAccountsKeeping = true
        item.GoodsProfitKeeping = false
        item.AccountsProfitKeeping = false
        item.InMoneyKeeping = false
        item.OutMoneyKeeping = false
        item.InPriceRecordKeeping = priceKeep
        item.OutPriceRecordKeeping = false
        item.OnLineOnTheWayKeeping = false
        item.DeleteOnLineOnTheWayKeeping = false
        item.SelfOnTheWayKeeping = false
        item.DeleteSelfOnTheWayKeeping = false
        item.TobeShippedKeeping = false
        item.DeleteTobeShippedKeeping = false
        item.OnLineAcountConfirmKeeping = false
        item.DeleteOnLineAcountConfirmKeeping = false
        item.InStockQty = item.OrderQty
        item.InStockStatus = 103
        if (item.VirtualGoods == true) {
          item.InStockKeeping = false
          item.InPriceRecordKeeping = false
          item.AccountsProfitKeeping = true
        }
      }
      const config = {
        method: "post",
        url: "/PoList/Add",
        data: contentBodyCopy,
      };
      requestData.GetData(config).then(res => {
        resolve(res)
      })
    })
  })
}

const openRrList = (body, docCenterBody, docTitle, stockCheck) => {
  return new Promise(function (resolve, reject) {
    let contentBodyCopy = copyArrayDoc(body, 'CommodityName')
    washBodyCopy(contentBodyCopy)
    let docHeadCopy = {}
    washDocHead(docCenterBody, docHeadCopy)
    openDocCenter(docHeadCopy, contentBodyCopy, docTitle).then(res => {
      if (res != 'pass') {
        alert(res)
        return false;
      }
      for (let item of contentBodyCopy) {
        item.InStockKeeping = stockCheck
        item.OutStockKeeping = false
        item.InAccountsKeeping = false
        item.OutAccountsKeeping = true
        item.GoodsProfitKeeping = stockCheck
        item.AccountsProfitKeeping = true
        item.InMoneyKeeping = false
        item.OutMoneyKeeping = false
        item.InPriceRecordKeeping = false
        item.OutPriceRecordKeeping = false
        item.OnLineOnTheWayKeeping = false
        item.DeleteOnLineOnTheWayKeeping = false
        item.SelfOnTheWayKeeping = false
        item.DeleteSelfOnTheWayKeeping = false
        item.TobeShippedKeeping = false
        item.DeleteTobeShippedKeeping = false
        item.OnLineAcountConfirmKeeping = false
        item.DeleteOnLineAcountConfirmKeeping = false
        item.InStockQty = item.OrderQty
        item.InStockStatus = 103
        if (item.VirtualGoods == true) {
          item.InStockKeeping = false
          item.GoodsProfitKeeping = false
        }
      }
      const config = {
        method: "post",
        url: "/RrList/Add",
        data: contentBodyCopy,
      };
      requestData.GetData(config).then(res => {
        resolve(res)
      })
    })
  })
}

const openStList = (body, docCenterBody, docTitle, instockCheck) => {
  return new Promise(function (resolve, reject) {
    let contentBodyCopy = copyArrayDoc(body, 'CommodityName')
    washBodyCopy(contentBodyCopy)
    let docHeadCopy = {}
    washDocHead(docCenterBody, docHeadCopy)
    openDocCenter(docHeadCopy, contentBodyCopy, docTitle).then(res => {
      if (res != 'pass') {
        alert(res)
        return false;
      }
      for (let item of contentBodyCopy) {
        item.InStockKeeping = true
        item.OutStockKeeping = true
        item.InAccountsKeeping = false
        item.OutAccountsKeeping = false
        item.GoodsProfitKeeping = false
        item.AccountsProfitKeeping = false
        item.InMoneyKeeping = false
        item.OutMoneyKeeping = false
        item.InPriceRecordKeeping = false
        item.OutPriceRecordKeeping = false
        item.OnLineOnTheWayKeeping = false
        item.DeleteOnLineOnTheWayKeeping = false
        item.SelfOnTheWayKeeping = false
        item.DeleteSelfOnTheWayKeeping = false
        item.TobeShippedKeeping = false
        item.DeleteTobeShippedKeeping = false
        item.OnLineAcountConfirmKeeping = false
        item.DeleteOnLineAcountConfirmKeeping = false
        item.InStockQty = item.OrderQty
        item.OutStockQty = item.OrderQty
        if (!instockCheck) {
          item.InStockKeeping = false
        }
      }
      const config = {
        method: "post",
        url: "/StList/Add",
        data: contentBodyCopy,
      };
      requestData.GetData(config).then(res => {
        resolve(res)
      })
    })
  })
}

const openMbList = (body, docCenterBody, docTitle) => {
  return new Promise(function (resolve, reject) {
    let contentBodyCopy = copyArrayDoc(body, 'CommodityName')
    washBodyCopy(contentBodyCopy)
    let docHeadCopy = {}
    washDocHead(docCenterBody, docHeadCopy)
    openDocCenter(docHeadCopy, contentBodyCopy, docTitle).then(res => {
      if (res != 'pass') {
        alert(res)
        return false;
      }
      for (let item of contentBodyCopy) {
        item.InStockKeeping = true
        item.OutStockKeeping = false
        item.InAccountsKeeping = false
        item.OutAccountsKeeping = true
        item.GoodsProfitKeeping = false
        item.AccountsProfitKeeping = false
        item.InMoneyKeeping = false
        item.OutMoneyKeeping = false
        item.InPriceRecordKeeping = false
        item.OutPriceRecordKeeping = false
        item.OnLineOnTheWayKeeping = false
        item.DeleteOnLineOnTheWayKeeping = false
        item.SelfOnTheWayKeeping = false
        item.DeleteSelfOnTheWayKeeping = false
        item.TobeShippedKeeping = false
        item.DeleteTobeShippedKeeping = false
        item.OnLineAcountConfirmKeeping = false
        item.DeleteOnLineAcountConfirmKeeping = false
        item.InStockQty = item.OrderQty
        item.InStockStatus = 103
      }
      const config = {
        method: "post",
        url: "/MbList/Add",
        data: contentBodyCopy,
      };
      requestData.GetData(config).then(res => {
        resolve(res)
      })
    })
  })
}

const openSoList = (body, docCenterBody, docTitle, checkDelevery) => {
  return new Promise(function (resolve, reject) {

    let contentBodyCopy = copyArrayDoc(body, 'CommodityName')
    washBodyCopy(contentBodyCopy)
    let docHeadCopy = {}
    washDocHead(docCenterBody, docHeadCopy)

    openDocCenter(docHeadCopy, contentBodyCopy, docTitle).then(res => {
      if (res != 'pass') {
        alert(res)
        return false;
      }
      for (let item of contentBodyCopy) {
        item.InStockKeeping = false
        item.OutStockKeeping = true
        item.InAccountsKeeping = true
        item.OutAccountsKeeping = false
        item.GoodsProfitKeeping = true
        item.AccountsProfitKeeping = true
        item.InMoneyKeeping = false
        item.OutMoneyKeeping = false
        item.InPriceRecordKeeping = false
        item.OutPriceRecordKeeping = true
        item.OnLineOnTheWayKeeping = false
        item.DeleteOnLineOnTheWayKeeping = false
        item.SelfOnTheWayKeeping = false
        item.DeleteSelfOnTheWayKeeping = false
        item.TobeShippedKeeping = checkDelevery ? false : true
        item.DeleteTobeShippedKeeping = false
        item.OnLineAcountConfirmKeeping = false
        item.DeleteOnLineAcountConfirmKeeping = false
        item.OutStockQty = item.OrderQty
        item.OutStockStatus = 103
        if (item.VirtualGoods == true) {
          item.OutStockKeeping = false
          item.GoodsProfitKeeping = false
        }
      }
      const config = {
        method: "post",
        url: "/SoList/Add",
        data: contentBodyCopy,
      };
      requestData.GetData(config).then(res => {
        resolve(res)
      })
    })
  })
}

const openRoList = (body, docCenterBody, docTitle) => {
  return new Promise(function (resolve, reject) {
    let contentBodyCopy = copyArrayDoc(body, 'CommodityName')
    washBodyCopy(contentBodyCopy)
    let docHeadCopy = {}
    washDocHead(docCenterBody, docHeadCopy)

    openDocCenter(docHeadCopy, contentBodyCopy, docTitle).then(res => {
      if (res != 'pass') {
        alert(res)
        return false;
      }
      for (let item of contentBodyCopy) {
        item.InStockKeeping = false
        item.OutStockKeeping = true
        item.InAccountsKeeping = true
        item.OutAccountsKeeping = false
        item.GoodsProfitKeeping = true
        item.AccountsProfitKeeping = true
        item.InMoneyKeeping = false
        item.OutMoneyKeeping = false
        item.InPriceRecordKeeping = false
        item.OutPriceRecordKeeping = true
        item.OnLineOnTheWayKeeping = false
        item.DeleteOnLineOnTheWayKeeping = false
        item.SelfOnTheWayKeeping = false
        item.DeleteSelfOnTheWayKeeping = false
        item.TobeShippedKeeping = false
        item.DeleteTobeShippedKeeping = false
        item.OnLineAcountConfirmKeeping = false
        item.DeleteOnLineAcountConfirmKeeping = false
        item.OutStockQty = item.OrderQty
        item.RootSourceDocumentId = docTitle
        item.OutStockStatus = 103
        if (item.VirtualGoods == true) {
          item.OutStockKeeping = false
          item.GoodsProfitKeeping = false
        }
      }
      const config = {
        method: "post",
        url: "/RoList/Add",
        data: contentBodyCopy,
      };
      requestData.GetData(config).then(res => {
        resolve(res)
      })
    })
  })

}


const openPrList = (body, docCenterBody, docTitle) => {
  return new Promise(function (resolve, reject) {
    let contentBodyCopy = copyArrayDoc(body, 'CommodityName')
    washBodyCopy(contentBodyCopy)
    let docHeadCopy = {}
    washDocHead(docCenterBody, docHeadCopy)
    openDocCenter(docHeadCopy, contentBodyCopy, docTitle).then(res => {
      if (res != 'pass') {
        alert(res)
        return false;
      }
      for (let item of contentBodyCopy) {
        item.InStockKeeping = false
        item.OutStockKeeping = true
        item.InAccountsKeeping = true
        item.OutAccountsKeeping = false
        item.GoodsProfitKeeping = true
        item.AccountsProfitKeeping = true
        item.InMoneyKeeping = false
        item.OutMoneyKeeping = false
        item.InPriceRecordKeeping = false
        item.OutPriceRecordKeeping = false
        item.OnLineOnTheWayKeeping = false
        item.DeleteOnLineOnTheWayKeeping = false
        item.SelfOnTheWayKeeping = false
        item.DeleteSelfOnTheWayKeeping = false
        item.TobeShippedKeeping = false
        item.DeleteTobeShippedKeeping = false
        item.OnLineAcountConfirmKeeping = false
        item.DeleteOnLineAcountConfirmKeeping = false
        item.OutStockQty = item.OrderQty
        item.OutStockStatus = 103
        if (item.VirtualGoods == true) {
          item.OutStockKeeping = false
          item.GoodsProfitKeeping = false
        }
      }
      const config = {
        method: "post",
        url: "/PrList/Add",
        data: contentBodyCopy,
      };
      requestData.GetData(config).then(res => {
        resolve(res)
      })
    })
  })
}


const openMsList = (body, docCenterBody, docTitle, checkDelevery) => {
  return new Promise(function (resolve, reject) {
    let contentBodyCopy = copyArrayDoc(body, 'CommodityName')
    washBodyCopy(contentBodyCopy)
    let docHeadCopy = {}
    washDocHead(docCenterBody, docHeadCopy)

    openDocCenter(docHeadCopy, contentBodyCopy, docTitle).then(res => {
      if (res != 'pass') {
        alert(res)
        return false;
      }
      for (let item of contentBodyCopy) {
        item.InStockKeeping = false
        item.OutStockKeeping = true
        item.InAccountsKeeping = true
        item.OutAccountsKeeping = false
        item.GoodsProfitKeeping = true
        item.AccountsProfitKeeping = true
        item.InMoneyKeeping = false
        item.OutMoneyKeeping = false
        item.InPriceRecordKeeping = false
        item.OutPriceRecordKeeping = false
        item.OnLineOnTheWayKeeping = false
        item.DeleteOnLineOnTheWayKeeping = false
        item.SelfOnTheWayKeeping = false
        item.DeleteSelfOnTheWayKeeping = false
        item.TobeShippedKeeping = checkDelevery ? false : true
        item.DeleteTobeShippedKeeping = false
        item.OnLineAcountConfirmKeeping = false
        item.DeleteOnLineAcountConfirmKeeping = false
        item.OutStockQty = item.OrderQty
        item.OutStockStatus = 103
      }
      const config = {
        method: "post",
        url: "/MsList/Add",
        data: contentBodyCopy,
      };
      requestData.GetData(config).then(res => {
        resolve(res)
      })
    })
  })
}


const rejectPurchaseApply = (onTheWayCode, qty, remark) => {
  const config = {
    method: "get",
    url: "/OnTheWayList/RejectPurchaseApply",
    params: {
      Code: onTheWayCode,
      Qty: qty,
      remark: remark
    }
  };
  return requestData.GetData(config)
}

const rejectSaleApply = (onTheWayCode, qty, remark) => {
  const config = {
    method: "get",
    url: "/SalesOnTheWayList/RejectSaleApply",
    params: {
      Code: onTheWayCode,
      Qty: qty,
      remark: remark
    }
  };
  return requestData.GetData(config)
}



const checkTtInWarehouse = (companyId, documentIdTt, inWarehouseId) => {
  const config = {
    method: "get",
    url: "/WareHouseList/CheckTtInWarehouse",
    params: {
      companyId: companyId,
      documentIdTt: documentIdTt,
      inWarehouseId: inWarehouseId
    }
  };
  return requestData.GetData(config)
}

const checkSoInWarehouse = (inWarehouseId) => {
  const config = {
    method: "get",
    url: "/WareHouseList/CheckSoInWarehouse",
    params: {
      inWarehouseId: inWarehouseId
    }
  };
  return requestData.GetData(config)
}

const updateInwarehouseId = (documentId, inWarehouseId) => {
  const config = {
    method: "get",
    url: "/DocumentCenter/UpdateInwarehouseId",
    params: {
      documentId: documentId,
      inWarehouseId: inWarehouseId
    }
  };
  return requestData.GetData(config)
}

const ttInstock = (ttObj) => {
  const config = {
    method: "post",
    url: "/TtList/TtInstock",
    data: ttObj
  };
  return requestData.GetData(config)
}

const updateTtOut = (onTheWayCode, saleQty, ttDocId, commodityId) => {
  const config = {
    method: "get",
    url: "/OnTheWayList/UpdateTtOut",
    params: {
      onTheWayCode: onTheWayCode,
      saleQty: saleQty,
      ttDocId: ttDocId,
      commodityId: commodityId
    }
  };
  return requestData.GetData(config)
}

const updatePoIn = (onTheWayCode, buyQty, poDocId, commodityId) => {
  const config = {
    method: "get",
    url: "/SalesOnTheWayList/UpdatePoIn",
    params: {
      onTheWayCode: onTheWayCode,
      buyQty: buyQty,
      poDocId: poDocId,
      commodityId: commodityId
    }
  };
  return requestData.GetData(config)
}


const updateSoOut = (onTheWayCode, saleQty, soDocId, commodityId) => {
  const config = {
    method: "get",
    url: "/OnTheWayList/UpdateSoOut",
    params: {
      onTheWayCode: onTheWayCode,
      saleQty: saleQty,
      soDocId: soDocId,
      commodityId: commodityId
    }
  };
  return requestData.GetData(config)
}

const deleteAcceptOnTheWay = (onTheWayCode, buyQty) => {
  const config = {
    method: "get",
    url: "/OnTheWayList/DeleteAcceptOnTheWay",
    params: {
      onTheWayCode: onTheWayCode,
      buyQty: buyQty
    }
  };
  return requestData.GetData(config)
}


const deleteSalesAcceptOnTheWay = (onTheWayCode, saleQty) => {
  const config = {
    method: "get",
    url: "/SalesOnTheWayList/DeleteSalesAcceptOnTheWay",
    params: {
      onTheWayCode: onTheWayCode,
      saleQty: saleQty
    }
  };
  return requestData.GetData(config)
}




const deleteOnTheWay = (onTheWayCode) => {
  const config = {
    method: "get",
    url: "/OnTheWayList/DeleteOnTheWay",
    params: {
      onTheWayCode: onTheWayCode
    }
  };
  return requestData.GetData(config)
}

const deleteSalesOnTheWay = (onTheWayCode) => {
  const config = {
    method: "get",
    url: "/SalesOnTheWayList/DeleteSalesOnTheWay",
    params: {
      onTheWayCode: onTheWayCode
    }
  };
  return requestData.GetData(config)
}

const getStaffId = (docId) => {
  const config = {
    method: "get",
    url: "/DocumentCenter/GetStaffId",
    params: {
      docId: docId
    }
  };
  return requestData.GetData(config)
}

const addMachineQueryList = (body) => {
  const config = {
    method: "post",
    url: "/MachineQueryList/Add",
    data: body
  };
  return requestData.GetData(config)
}

const getBookInfo = (machineId) => {
  const config = {
    method: "get",
    url: "/MachineQueryList/GetBookInfo",
    params: {
      machineId: machineId
    }
  };
  return requestData.GetData(config)
}

const checkBookOrNot = (machineId) => {
  const config = {
    method: "get",
    url: "/MachineQueryList/CheckBookOrNot",
    params: {
      machineId: machineId
    }
  };
  return requestData.GetData(config)
}

const deleteBookApply = (machineId) => {
  const config = {
    method: "get",
    url: "/MachineQueryList/DeleteBookApply",
    params: {
      machineId: machineId
    }
  };
  return requestData.GetData(config)
}

const sendRejectApply = (queryId) => {
  const config = {
    method: "get",
    url: "/MachineQueryList/RejectApply",
    params: {
      queryId: queryId
    }
  };
  return requestData.GetData(config)
}

const getStaffIdByMachineId = (machineStorageId) => {
  const config = {
    method: "get",
    url: "/MachineStorageList/GetStaffIdByMachineId",
    params: {
      machineStorageId: machineStorageId
    }
  };
  return requestData.GetData(config)
}

const updateSaleInfoForMachineQuery = (queryId) => {
  const config = {
    method: "get",
    url: "/MachineQueryList/UpdateSaleInfoForMachineQuery",
    params: {
      queryId: queryId
    }
  };
  return requestData.GetData(config)
}

const deleteMachineQuery = (machineQueryCode) => {
  const config = {
    method: "delete",
    url: "/MachineQueryList/Delete",
    params: {
      id: machineQueryCode
    }
  };
  return requestData.GetData(config)
}

const rejectAllQuery = (machineId) => {
  const config = {
    method: "get",
    url: "/MachineQueryList/RejectAllQuery",
    params: {
      machineId: machineId
    }
  };
  return requestData.GetData(config)
}

const repairUpdateStaff = (repairCode, staffId) => {
  const config = {
    method: "get",
    url: "/RepairRecordList/UpdateStaffId",
    params: {
      repairCode: repairCode,
      staffId: staffId,
    }
  };
  return requestData.GetData(config)
}

const updateOutSourceCompany = (documentId, outSourceCompanyId, remark) => {
  const config = {
    method: "get",
    url: "/RepairRecordList/UpdateOutSourceInfo",
    params: {
      documentId: documentId,
      outSourceCompanyId: outSourceCompanyId,
      remark: remark
    }
  };
  return requestData.GetData(config)
}

const deleteRepairRecord = (repairRecordCode) => {
  const config = {
    method: "get",
    url: "/RepairRecordList/ReturnMachineRepair",
    params: {
      repairRecordCode: repairRecordCode
    }
  };
  return requestData.GetData(config)
}

const repairOk = (repairRecordCode) => {
  const config = {
    method: "get",
    url: "/RepairRecordList/RepairOk",
    params: {
      repairRecordCode: repairRecordCode
    }
  };
  return requestData.GetData(config)
}

const inspectOk = (repairRecordCode) => {
  const config = {
    method: "get",
    url: "/RepairRecordList/InspectOk",
    params: {
      repairRecordCode: repairRecordCode
    }
  };
  return requestData.GetData(config)
}

const outSourceReceive = (repairRecordCode) => {
  const config = {
    method: "get",
    url: "/RepairRecordList/OutSourceReceive",
    params: {
      repairRecordCode: repairRecordCode
    }
  };
  return requestData.GetData(config)
}

const outSourceBackConfirm = (repairRecordCode) => {
  const config = {
    method: "get",
    url: "/RepairRecordList/OutSourceBackConfirm",
    params: {
      repairRecordCode: repairRecordCode
    }
  };
  return requestData.GetData(config)
}

const sendNoticeCustomer = (repairRecordCode) => {
  const config = {
    method: "get",
    url: "/RepairRecordList/SendNoticeCustomer",
    params: {
      repairRecordCode: repairRecordCode
    }
  };
  return requestData.GetData(config)
}

const addRecycleOnTheWay = (content) => {
  const config = {
    method: "post",
    url: "/RecyleOnTheWayList/Add",
    data: content
  };
  return requestData.GetData(config)
}

const getRelatedDocNo = (docOrigin) => {
  const config = {
    method: "get",
    url: "/AppendDocumentMappingList/GetRelatedDocNo",
    params: {
      docOrigin: docOrigin
    }
  };
  return requestData.GetData(config)
}

const deleteRecycleOnTheWay = (id, newAttrNo, newIsQty, newFineMoney, SolvedUserid, inWarehouseId) => {
  const config = {
    method: "delete",
    url: "/RecyleOnTheWayDoneList/DeleteNew",
    params: {
      id: id,
      newAttrNo: newAttrNo,
      newIsQty: newIsQty,
      newFineMoney: newFineMoney,
      SolvedUserid: SolvedUserid,
      inWarehouseId: inWarehouseId
    }
  };
  return requestData.GetData(config)
}

const updateRecyclePrice = (recycleCode, newPrice) => {
  const config = {
    method: "get",
    url: "/RecyleOnTheWayList/UpdateRecyclePrice",
    params: {
      recycleCode: recycleCode,
      newPrice: newPrice
    }
  };
  return requestData.GetData(config)
}

const addMoneyToBePaid = (body) => {
  const config = {
    method: "post",
    url: "/MoneyToBePayImmediateList/Add",
    data: body,
  };
  return requestData.GetData(config)
}

const addStOnTheWay = (body) => {
  const config = {
    method: "post",
    url: "/StOnTheWayList/Add",
    data: body,
  };
  return requestData.GetData(config)
}

const refuseStOnTheWay = (stOnTheWayCode) => {
  const config = {
    method: "get",
    url: "/StList/RefuseAction",
    params: {
      stOnTheWayCode: stOnTheWayCode
    }
  };
  return requestData.GetData(config)
}

const inStockStOnTheWay = (stOnTheWayCode) => {
  const config = {
    method: "get",
    url: "/StList/InStockAction",
    params: {
      stOnTheWayCode: stOnTheWayCode
    }
  };
  return requestData.GetData(config)
}

const getUserInfo = (userId) => {
  const config = {
    method: "get",
    url: "/RdsUsers/GetUserInfo",
    params: {
      userId: userId
    }
  };
  return requestData.GetData(config)
}

const updateLoginInfo = (body) => {
  const config = {
    method: "post",
    url: "/RdsUsers/UpdateInfo",
    data: body,
  };
  return requestData.GetData(config)
}

const getUserAuto = (userId) => {
  const config = {
    method: "get",
    url: "/RdsUsers/GetUserAuthorities",
    params: {
      userId: userId
    }
  };
  return requestData.GetData(config)
}

const updateWarehouseAuth = (bodys) => {
  const config = {
    method: "post",
    url: "/WarehouseAuthority/UpdateAutho",
    data: bodys
  };
  return requestData.GetData(config)
}

const updateBpAuth = (bodys) => {
  const config = {
    method: "post",
    url: "/BusinessPartnerAuthority/UpdateAutho",
    data: bodys
  };
  return requestData.GetData(config)
}

const updateSeeAllWarehouse = (userId, seeAllWarehouse) => {
  const config = {
    method: "get",
    url: "/UserConfigList/UpdateUserWare",
    params: {
      userId: userId,
      seeAllWarehouse: seeAllWarehouse
    }
  };
  return requestData.GetData(config)
}

const updateSeeAllBp = (userId, seeAllBp) => {
  const config = {
    method: "get",
    url: "/UserConfigList/UpdateUserBp",
    params: {
      userId: userId,
      seeAllBp: seeAllBp
    }
  };
  return requestData.GetData(config)
}

const updateCostVisibility = (userId, costVisi) => {
  const config = {
    method: "get",
    url: "/CostVisibilityMange/UpdateCostVisibility",
    params: {
      userId: userId,
      costVisi: costVisi
    }
  };
  return requestData.GetData(config)
}


const updateMainManuAuth = (bodys) => {
  const config = {
    method: "post",
    url: "/MainManuManage/UpdateUserInfo",
    data: bodys
  };
  return requestData.GetData(config)
}

const updateViewAuth = (bodys) => {
  const config = {
    method: "post",
    url: "/ViewVisibleManage/UpdateUserInfo",
    data: bodys
  };
  return requestData.GetData(config)
}

const updateButtonAuth = (bodys) => {
  const config = {
    method: "post",
    url: "/ButtonVisibleManage/UpdateUserInfo",
    data: bodys
  };
  return requestData.GetData(config)
}

const getTobeCopyList = (companyId) => {
  const config = {
    method: "get",
    url: "/RoleList/GetRoleListToCopy",
    params: {
      partAId: companyId
    }
  };
  return requestData.GetData(config)
}

const sendChangePw = (oldOne, newOne, ConfirmOne) => {
  const config = {
    method: "get",
    url: "/RdsUsers/ChangePwd",
    params: {
      oldOne: oldOne,
      newOne: newOne,
      confirmOne: ConfirmOne
    }
  };
  return requestData.GetData(config)
}

const updateWarning = (partAId, commoArr) => {
  if (!commoArr.length) {
    return;
  }

  if (!isRealNum(partAId)) {
    partAId = transferToCode(partAId,'BusinessPartnerId')
  }

  for (let item of commoArr) {
    const config = {
      method: "get",
      url: "/CommodityWarningList/UpdateWarning",
      params: {
        partAId: partAId,
        CommodityId: item
      }
    };
    requestData.GetCache(config)
  }
}

const updateWarningAll = () => {


  const config = {
    method: "get",
    url: "/CommodityWarningList/UpdateAllWarning",
    params: {

    }
  };
  requestData.GetCache(config)
}

const getPartBIdBydocId = (docId) => {
  const config = {
    method: "get",
    url: "/DocumentCenter/GetPartBId",
    params: {
      docId: docId
    }
  };
  return requestData.GetData(config)
}

const getCommodityIdsByObId = (obId) => {
  const config = {
    method: "get",
    url: "/ObList/GetCommodityIdsByObId",
    params: {
      obId: obId
    }
  };
  return requestData.GetData(config)
}

const getDetailProfitList = (dateChoose, docType, StaffId) => {
  const config = {
    method: "get",
    url: "/AccountsProfitTable/GetDetailFromProfitShow",
    params: {
      dateChoose: dateChoose,
      docType: docType,
      StaffId: StaffId
    }
  };
  return requestData.GetData(config)
}

const updateProfitPerByDocId = (docId, StaffId) => {
  const config = {
    method: "get",
    url: "/AccountsProfitTable/UpdateProfitPerByDocId",
    params: {
      docId: docId,
      StaffId: StaffId,
    }
  };
  return requestData.GetData(config)
}

const updateProfitPerBySourDocId = (docId, StaffId) => {
  const config = {
    method: "get",
    url: "/AccountsProfitTable/UpdateProfitPerBySourDocId",
    params: {
      docId: docId,
      StaffId: StaffId,
    }
  };
  return requestData.GetData(config)
}

const getVmiCost = (companyId, commodityId) => {
  const config = {
    method: "get",
    url: "/VmiPriceMethodList/GetVmiCost",
    params: {
      companyId: companyId,
      commodityId: commodityId,
    }
  };
  return requestData.GetData(config)
}

const getVmiCostByAttr = (companyId, commodityId, attrId) => {
  const config = {
    method: "get",
    url: "/VmiPriceMethodList/GetVmiCostByAttr",
    params: {
      companyId: companyId,
      commodityId: commodityId,
      attrId: attrId
    }
  };
  return requestData.GetCache(config)
}

const getShippedPrice = (shipCode) => {
  const config = {
    method: "get",
    url: "/ToBeShippedList/GetShipPrice",
    params: {
      shipCode: shipCode,
    }
  };
  return requestData.GetData(config)
}

const getShipTargetInfo = (partBName) => {
  const config = {
    method: "get",
    url: "/ToBeShippedList/GetShipTargetInfo",
    params: {
      partBName: partBName,
    }
  };
  return requestData.GetData(config)
}

const deleteToBeShippedRecord = (toBeShippedCode, userId) => {
  const config = {
    method: "delete",
    url: "/ToBeShippedList/Delete",
    params: {
      id: toBeShippedCode,
      userId: userId
    }
  };
  return requestData.GetData(config)
}

const updateToBeShippedRecord = (toBeShippedCode, leftQty, userId) => {
  const config = {
    method: "get",
    url: "/ToBeShippedList/UpdateShipRecord",
    params: {
      toBeShippedCode: toBeShippedCode,
      leftQty: leftQty,
      userId: userId
    }
  };
  return requestData.GetData(config)
}

const getFriendStockList = (commoId, compaId) => {
  const config = {
    method: "get",
    url: "/InventoryBalanceList/GetFriendStockList",
    params: {
      commoId: commoId,
      compaId: compaId
    }
  };
  return requestData.GetData(config)
}

const getComNameById = (commoId) => {
  const config = {
    method: "get",
    url: "/CommodityMangeTable/GetNameById",
    params: {
      commoId: commoId
    }
  };
  return requestData.GetCache(config)
}

const getRetailPriceById = (commId) => {
  const config = {
    method: "get",
    url: "/VmiPriceMethodList/GetRetialPrice",
    params: {
      commId: commId
    }
  };
  return requestData.GetCache(config)
}

const getSourceFrcode = (frcode) => {
  const config = {
    method: "get",
    url: "/FrList/GetSourceFrCode",
    params: {
      frcode: frcode
    }
  };
  return requestData.GetData(config)
}

const openOnTheWayList = (body) => {
  const config = {
    method: "post",
    url: "/OnTheWayList/Add",
    data: body
  };
  return requestData.GetData(config)
}

const getFrStaff = (docId) => {
  const config = {
    method: "get",
    url: "/RepairRecordList/GetFrStaff",
    params: {
      docId: docId
    }
  };
  return requestData.GetData(config)
}

const combineProfit = (exDocId, targetDocId) => {
  const config = {
    method: "get",
    url: "/AccountsProfitTable/CombineProfit",
    params: {
      exDocId: exDocId,
      targetDocId: targetDocId
    }
  };
  return requestData.GetData(config)
}

const getOnTheWayQty = (commoId, companyId = '') => {
  const config = {
    method: "get",
    url: "/OnTheWayList/GetOneTheWayQty",
    params: {
      commoId: commoId,
      companyId: companyId
    }
  };
  return requestData.GetCache(config)
}

const getCreditValue = (staffId) => {
  const config = {
    method: "get",
    url: "/StaffCreditList/GetCreditValue",
    params: {
      staffId: staffId
    }
  };
  return requestData.GetCache(config)
}

const updateStaffCredit = (staffId, Staffcredit) => {
  const config = {
    method: "get",
    url: "/StaffCreditList/UpdateCreditValue",
    params: {
      staffId: staffId,
      Staffcredit: Staffcredit
    }
  };
  return requestData.GetData(config)
}

const getMiListUnCheck = (companyId) => {
  const config = {
    method: "get",
    url: "/ReportShow/GetMiListUnCheck",
    params: {
      companyId: companyId,
    }
  };
  return requestData.GetData(config)
}

const collectMiMoney = (amount, companyId) => {
  const config = {
    method: "get",
    url: "/ReportShow/CollectMiMoney",
    params: {
      amount: amount,
      companyId,
      companyId
    }
  };
  return requestData.GetData(config)
}

const getDocHeadInfo = (docId) => {
  const config = {
    method: "get",
    url: "/DocumentCenter/GetDocInfoById",
    params: {
      docId: docId
    }
  };
  return requestData.GetData(config)
}


const copyOrder = (docInfo, head, body, bodySample, toPage = 'copy') => {
  return new Promise(function (resolve, reject) {
    const docId = docInfo.documentId
    const docJude = docId.substring(0, 1) + docId.substring(1, 2).toLowerCase()
    const docName = docJude + 'List'
    const docHeadFields = store.state.loginInitializeData.docTableElementManage.filter(x => x.docTableName == docName).filter(y => y.fieldsType == 'Head')
    const docBodyFields = store.state.loginInitializeData.listForValidations.filter(x => x.tableNameForVali == docName && x.upLoadShow == true && x.validateMethod == 'add')
    getDocHeadInfo(docId).then(res => {
      if (res.documentId) {
        head['DocumentId'] = res['documentId']
        head['PartACompanyId'] = res['partACompanyId']
        head['Amount'] = res['amount']
        head['Qty'] = res['qty']
        head['SystemStatusId'] = res['systemStatusId']
        if (docHeadFields.length > 0) {
          for (let headItem of docHeadFields) {
            if (toPage == 'copy') {
              if(headItem.fieldsNameForManage == 'Remark'){
                head[headItem.fieldsNameForManage] = res[headItem.fieldsNameForManage.substring(0, 1).toLowerCase() + headItem.fieldsNameForManage.substring(1)]
              }
              else{
                head[headItem.fieldsNameForManage] = transferToName(res[headItem.fieldsNameForManage.substring(0, 1).toLowerCase() + headItem.fieldsNameForManage.substring(1)], headItem.fieldsNameForManage)
              }
              
            } else {
              head[headItem.fieldsNameForManage] = res[headItem.fieldsNameForManage.substring(0, 1).toLowerCase() + headItem.fieldsNameForManage.substring(1)]
            }
          }
          head['DocumentDateTime'] = res['documentDateTime']
          if (docJude == 'Ro') {
            head['PartBCompanyId'] = res['partBCompanyId']
            head['OutWareHouseId'] = res['outWareHouseId']
            head['StaffId'] = res['staffId']
            head['Remark'] = res['remark']
            head['DocumentDateTime'] = res['documentDateTime']
          }
        } else {

        }
      }
      const url1 = '/' + docName + '/GetBodyInfo'
      let config = {
        method: "get",
        url: url1,
        params: {
          docId: docId
        }
      }
      requestData.GetData(config).then(res => {
        if (res.length > 0) {
          const length1 = res.length
          if (length1 > 18) {
            for (let j = 0; j <= length1 - 18; j++) {
              body.push(copyObj(bodySample))
            }
          }
          for (let k = 0; k < length1; k++) {
            if (toPage == 'Edit') {
              body[k]['DocumentId'] = res[k]['documentId']
              body[k]['Sn'] = res[k]['sn']
              body[k]['PartACompanyId'] = res[k]['partACompanyId']
            }
            if (docBodyFields.length > 0) {
              for (let bodyItem of docBodyFields) {
                if (bodyItem.fieldsNameForVali == 'Remark' && toPage == 'copy') {
                  continue
                }
                body[k][bodyItem.fieldsNameForVali] = res[k][bodyItem.fieldsNameForVali.substring(0, 1).toLowerCase() + bodyItem.fieldsNameForVali.substring(1)]
              }
            } else {
              if (docJude == 'Ro') {
                body[k]['CommodityId'] = res[k]['commodityId']
                body[k]['CommodityName'] = res[k]['commodityName']
                body[k]['OrderQty'] = res[k]['orderQty']
                body[k]['SalesAttributeNo'] = res[k]['salesAttributeNo']
                body[k]['Unit'] = res[k]['unit']
                body[k]['UnitPrice'] = res[k]['unitPrice']
                body[k]['VirtualGoods'] = res[k]['virtualGoods']
                body[k]['Amount'] = res[k]['amount']
                body[k]['Remark'] = res[k]['remark']
                getVmiCid().then(cid => {
                  getArrAttriByCoId(res[k]['commodityId'], cid).then(arr => {
                    for (let item4 of arr) {
                      item4.salesAttributeName = transferToName(item4.salesAttributeId, "SalesAttributeId")
                    }
                    body[k].SalesAttributeNoArr = copyArray(arr)

                  })
                })
              }
            }
          }
          resolve('pass')
        } else {
          resolve('pass')
        }
      })
    })
  })
}

const getDiscountValue = (docId) => {
  const config = {
    method: "get",
    url: "/DocumentCenter/GetDiscountValue",
    params: {
      docId: docId,
    }
  };
  return requestData.GetData(config)
}

const getPlatReceive = (docId) => {
  const config = {
    method: "get",
    url: "/DocumentCenter/GetPlatReceiveDoc",
    params: {
      docId: docId,
    }
  };
  return requestData.GetData(config)
}

const getCustomerSource = (docId) => {
  const config = {
    method: "get",
    url: "/CustomerSourceStatistics/GetCustomerSource",
    params: {
      docId: docId,
    }
  };
  return requestData.GetData(config)
}

const getObTracingInfo = (docId) => {
  const config = {
    method: "get",
    url: "/ObTracingList/GetObTracingById",
    params: {
      docId: docId,
    }
  };
  return requestData.GetData(config)
}

const checkPoLineBeforeEdit = (docId, sn, stockBalanceDecrease) => {
  const config = {
    method: "get",
    url: "/PoList/CheckPoLineBeforeEdit",
    params: {
      docId: docId,
      sn: sn,
      stockBalanceDecrease: stockBalanceDecrease,
    }
  };
  return requestData.GetData(config)
}

const deleteOnePoLineTrue = (docId, sn) => {
  const config = {
    method: "get",
    url: "/PoList/DeleteOnePoLine",
    params: {
      docId: docId,
      sn: sn,
    }
  };
  return requestData.GetData(config)
}

const fakeDeleteDocumentCenter = (docId) => {
  const config = {
    method: "get",
    url: "/DocumentCenter/FakeDeleteDocumentCenter",
    params: {
      docId: docId,
    }
  };
  return requestData.GetData(config)
}

const updateDocCenter = (docId, addQty, addAmount) => {
  const config = {
    method: "get",
    url: "/DocumentCenter/UpdateDocCenter",
    params: {
      docId: docId,
      addQty: addQty,
      addAmount: addAmount
    }
  };
  return requestData.GetData(config)
}

const updateStaffIdCheck = (docId, newStaffId) => {
  const config = {
    method: "get",
    url: "/DocumentCenter/UpdateStaffIdCheck",
    params: {
      docId: docId,
      newStaffId: newStaffId,
    }
  };
  return requestData.GetData(config)
}

const updateStaffId = (docId, newStaffId) => {
  const config = {
    method: "get",
    url: "/DocumentCenter/UpdateStaffId",
    params: {
      docId: docId,
      newStaffId: newStaffId,
    }
  };
  return requestData.GetData(config)
}

const updateHeadRemarkReal = (docId, newRemark) => {
  const config = {
    method: "get",
    url: "/DocumentCenter/UpdateHeadRemark",
    params: {
      docId: docId,
      newRemark: newRemark
    }
  };
  return requestData.GetData(config)
}

const updatePoBodyRemarkReal = (docId, sn, newRemark) => {
  const config = {
    method: "get",
    url: "/PoList/UpdatePoBodyRemark",
    params: {
      docId: docId,
      sn: sn,
      newRemark: newRemark,
    }
  };
  return requestData.GetData(config)
}

const updateSoBodyRemarkReal = (docId, sn, newRemark) => {
  const config = {
    method: "get",
    url: "/SoList/UpdateSoBodyRemark",
    params: {
      docId: docId,
      sn: sn,
      newRemark: newRemark,
    }
  };
  return requestData.GetData(config)
}

const updateRoBodyRemarkReal = (docId, sn, newRemark) => {
  const config = {
    method: "get",
    url: "/RoList/UpdateRoBodyRemark",
    params: {
      docId: docId,
      sn: sn,
      newRemark: newRemark,
    }
  };
  return requestData.GetData(config)
}




const updatePoLineUnitPriceCheckReal = (docId, sn, priceChange) => {
  const config = {
    method: "get",
    url: "/PoList/UpdatePoLineUnitPriceCheck",
    params: {
      docId: docId,
      sn: sn,
      priceChange: priceChange,
    }
  };
  return requestData.GetData(config)
}

const updateSoLineUnitPriceCheckReal = (docId, sn, priceChange) => {
  const config = {
    method: "get",
    url: "/SoList/UpdateSoLineUnitPriceCheck",
    params: {
      docId: docId,
      sn: sn,
      priceChange: priceChange,
    }
  };
  return requestData.GetData(config)
}

const updateRoLineUnitPriceCheckReal = (docId, sn, priceChange) => {
  const config = {
    method: "get",
    url: "/RoList/UpdateRoLineUnitPriceCheck",
    params: {
      docId: docId,
      sn: sn,
      priceChange: priceChange,
    }
  };
  return requestData.GetData(config)
}

const updatePoLineUnitPriceReal = (docId, sn, priceChange) => {
  const config = {
    method: "get",
    url: "/PoList/UpdatePoLineUnitPrice",
    params: {
      docId: docId,
      sn: sn,
      priceChange: priceChange,
    }
  };
  return requestData.GetData(config)
}

const updateSoLineUnitPriceReal = (docId, sn, priceChange) => {
  const config = {
    method: "get",
    url: "/SoList/UpdateSoLineUnitPrice",
    params: {
      docId: docId,
      sn: sn,
      priceChange: priceChange,
    }
  };
  return requestData.GetData(config)
}

const updateRoLineUnitPriceReal = (docId, sn, priceChange) => {
  const config = {
    method: "get",
    url: "/RoList/UpdateRoLineUnitPrice",
    params: {
      docId: docId,
      sn: sn,
      priceChange: priceChange,
    }
  };
  return requestData.GetData(config)
}

const checkSoLineDelete = (docId, loseValue) => {
  const config = {
    method: "get",
    url: "/SoList/CheckSoLineDelete",
    params: {
      docId: docId,
      loseValue: loseValue
    }
  };
  return requestData.GetData(config)
}

const deleteOneSoLineTrue = (docId, sn) => {
  const config = {
    method: "get",
    url: "/SoList/DeleteOneSoLine",
    params: {
      docId: docId,
      sn: sn
    }
  };
  return requestData.GetData(config)
}

const updateDiscoutCheckReal = (docId, changeValue) => {
  const config = {
    method: "get",
    url: "/DocumentCenter/UpdateDiscoutCheck",
    params: {
      docId: docId,
      changeValue: changeValue
    }
  };
  return requestData.GetData(config)
}

const dbCheckMiDefinite = (docId, confirmAmount) => {
  const config = {
    method: "get",
    url: "/ReportShow/DbCheckMiDefinite",
    params: {
      docId: docId,
      confirmAmount: confirmAmount
    }
  };
  return requestData.GetData(config)
}

const checkRoLineDelete = (docId, sn) => {
  const config = {
    method: "get",
    url: "/RoList/CheckRoLineDelete",
    params: {
      docId: docId,
      sn: sn
    }
  };
  return requestData.GetData(config)
}

const checkRbLineDelete = (docId) => {
  const config = {
    method: "get",
    url: "/RbList/CheckRbLineDelete",
    params: {
      docId: docId
    }
  };
  return requestData.GetData(config)
}




const deleteOneRoLineTrue = (docId, sn) => {
  const config = {
    method: "get",
    url: "/RoList/DeleteOneRoLine",
    params: {
      docId: docId,
      sn: sn
    }
  };
  return requestData.GetData(config)
}

const deleteOneRbLineTrue = (docId, sn) => {
  const config = {
    method: "get",
    url: "/RbList/DeleteOneRbLine",
    params: {
      docId: docId,
      sn: sn
    }
  };
  return requestData.GetData(config)
}

const updateRoSaleAttrCheckReal = (docId, sn, newAttr) => {
  const config = {
    method: "get",
    url: "/RoList/UpdateRoSaleAttrCheck",
    params: {
      docId: docId,
      sn: sn,
      newAttr: newAttr
    }
  };
  return requestData.GetData(config)
}

const updateRoSaleAttrReal = (docId, sn, newAttr) => {
  const config = {
    method: "get",
    url: "/RoList/UpdateRoSaleAttr",
    params: {
      docId: docId,
      sn: sn,
      newAttr: newAttr
    }
  };
  return requestData.GetData(config)
}

const updateCustomerSourceReal = (docId, newSource) => {
  const config = {
    method: "get",
    url: "/RoList/UpdateCustomerSource",
    params: {
      docId: docId,
      newSource: newSource,
    }
  };
  return requestData.GetData(config)
}

const getCounterPartInven = (companyId, commodityId) => {
  const config = {
    method: "get",
    url: "/InventoryBalanceList/GetInvenByCompanyId",
    params: {
      companyId: companyId,
      commodityId: commodityId,
    }
  };
  return requestData.GetCache(config)
}

const getToBeInStockQty = (companyId, CommodityId) => {
  const config = {
    method: "get",
    url: "/OnTheWayList/GetToBeInStockQty",
    params: {
      companyId: companyId,
      CommodityId: CommodityId,
    }
  };
  return requestData.GetCache(config)
}

const getFrAddress = (docId) => {
  const config = {
    method: "get",
    url: "/FrList/GetFrAddress",
    params: {
      docId: docId,
    }
  };
  return requestData.GetData(config)
}

const checkSalarySettle = (docId) => {
  const config = {
    method: "get",
    url: "/AccountsProfitTable/CheckSalarySettle",
    params: {
      docId: docId,
    }
  };
  return requestData.GetData(config)
}

const addProfit = (body) => {
  const config = {
    method: "post",
    url: "/AccountsProfitTable/add",
    data: body
  };
  return requestData.GetData(config)
}

const addPlatformReal = (platformId) => {
  const config = {
    method: "get",
    url: "/BusinessPartnerList/AddPlatformReal",
    params: {
      platformId: platformId,
    }
  };
  return requestData.GetData(config)
}

const addPlatformForGroupReal = (platformId) => {
  const config = {
    method: "get",
    url: "/BusinessPartnerList/AddPlatformForGroupReal",
    params: {
      platformId: platformId,
    }
  };
  return requestData.GetData(config)
}

const getDocDetail = (docId) => {
  const config = {
    method: "get",
    url: "/DocumentCenter/GetDocDetail",
    params: {
      docId: docId,
    }
  };
  return requestData.GetCache(config)

}

const alterOnTheWayForSaleCommodityId = (onTheWayId, CommId) => {
  const config = {
    method: "get",
    url: "/OnTheWayList/AlterOnTheWayForSaleCommodityId",
    params: {
      onTheWayId: onTheWayId,
      CommId,
      CommId
    }
  };
  return requestData.GetCache(config)
}

const updateRecycleOnTheWayDoneList = (recyleOnTheWayDoneCode, newAttrNo, newFineMoney, SolvedUserid, outQty) => {
  const config = {
    method: "get",
    url: "/RecyleOnTheWayDoneList/UpdateRecycleOnTheWayDoneList",
    params: {
      recyleOnTheWayDoneCode: recyleOnTheWayDoneCode,
      newAttrNo,
      newAttrNo,
      newFineMoney: newFineMoney,
      SolvedUserid: SolvedUserid,
      outQty: outQty
    }
  };
  return requestData.GetCache(config)
}

const getQuoList = (commId) => {
  const config = {
    method: "get",
    url: "/QuotationList/GetQuoListById",
    params: {
      commId: commId,
    }
  };
  return requestData.GetCache(config)
}

const getMachineTypeList = (firstId, machineId) => {
  const config = {
    method: "get",
    url: "/MachineTypeList/GetMachineTypeList",
    params: {
      machineId: machineId,
      firstId: firstId
    }
  };
  return requestData.GetCache(config)
}

const getAllCommByCommodityId = (commoId) => {
  const config = {
    method: "get",
    url: "/CommodityMangeTable/GetAllCommByCommodityId",
    params: {
      commoId: commoId,
    }
  };
  return requestData.GetData(config)
}

const getAllVmiByCommodityId = (commoId) => {
  const config = {
    method: "get",
    url: "/VmiPriceMethodList/GetAllVmiByCommodityId",
    params: {
      commoId: commoId,
    }
  };
  return requestData.GetData(config)
}

const getAllCommPurchasedToday = (chooseDate) => {
  const config = {
    method: "get",
    url: "/PoList/GetAllCommPurchasedToday",
    params:{
      chooseDate:chooseDate
    }
  };
  return requestData.GetData(config)
}

const getAllVmiByCommArr = (commodityMangeTables) => {
  const config = {
    method: "post",
    url: "/VmiPriceMethodList/GetAllVmiByCommArr",
    data: commodityMangeTables
  };
  return requestData.GetData(config)
}

const sendBenchMarkChange = (body) => {
  const config = {
    method: "post",
    url: "/BenchMarkChangeSubmit/AddNew",
    data: body
  };
  return requestData.GetData(config)
}

const addAccountsChangeOnTheWay = (bodyCopy, partBNo, staffName, gdHeadCode) => {
  const lenBody = bodyCopy.length
  const typeJudge = gdHeadCode.substring(0, 2) == 'GD' ? '扣款单' : '奖励单'
  if (lenBody > 0) {
    for (let i = 0; i < lenBody; i++) {
      let b = {
        DocumentId: gdHeadCode,
        Sn: i + 1,
        PartACompanyId: partBNo,
        PartBCompanyId: store.state.loginInitializeData.companyId,
        DocumentType: typeJudge,
        UnitPrice: bodyCopy[i].UnitPrice,
        Remark: bodyCopy[i].Remark + `-开单人:${staffName}`,
      }
      const config = {
        method: "post",
        url: "/AccountsChangeOnTheWayList/Add",
        data: b
      };
      requestData.GetCache(config).then(res => {})
    }
  }
}

const deleteAccountsChangeOnTheWayList = (code) => {
  const config = {
    method: "delete",
    url: "/AccountsChangeOnTheWayList/Delete",
    params: {
      id: code
    }
  };
  return requestData.GetData(config)
}

const initializeOutSourceAppendInfo = (docCode, emergencyTpye, outSourceId) => {
  const config = {
    method: "get",
    url: "/OutSouceAppendInfoList/InitializeOutSourceAppendInfo",
    params: {
      docCode: docCode,
      emergencyTpye: emergencyTpye,
      outSourceId: outSourceId
    }
  };
  return requestData.GetData(config).then(res => {

  })
}

const getFrBody = (docId) => {
  const config = {
    method: "get",
    url: "/RepairRecordList/GetFrBody",
    params: {
      docId: docId
    }
  };
  return requestData.GetCache(config)
}

const getOutSourceAppendInfo = (docId) => {
  const config = {
    method: "get",
    url: "/OutSouceAppendInfoList/GetOutSourceAppendInfo",
    params: {
      docId: docId
    }
  };
  return requestData.GetCache(config)
}

const addQuotationForFrList = (repairCode, price, remark) => {
  const config = {
    method: "get",
    url: "/RepairRecordList/AddQuotationForFrList",
    params: {
      repairCode: repairCode,
      price: price,
      remark: remark
    }
  };
  return requestData.GetData(config)
}

const confirmQuotationReal = (repairCode, remark) => {
  const config = {
    method: "get",
    url: "/RepairRecordList/ConfirmQuotationReal",
    params: {
      repairCode: repairCode,
      remark: remark
    }
  };
  return requestData.GetData(config)
}

const getAllWarningList = (companyId, commoSearch) => {
  const config = {
    method: "get",
    url: "/CommodityWarningList/GetAllWarningList",
    params: {
      companyId: companyId,
      commoSearch: commoSearch
    }
  };
  return requestData.GetData(config)
}

const getCommodityBodyById = (commId) => {
  const config = {
    method: "get",
    url: "/CommodityMangeTable/GetCommoBodyById",
    params: {
      commId: commId,
    }
  };
  return requestData.GetData(config)
}

const checkSetUpCondition = (userId) => {
  const config = {
    method: "get",
    url: "/Register/CheckSetUpLowerCompanyCondition",
    params: {
      userId: userId,
    }
  };
  return requestData.GetData(config)
}

const getCompanyName = (companyId) => {
  const config = {
    method: "get",
    url: "/CompanyUserManage/GetCompanyName",
    params: {
      companyId: companyId,
    }
  };
  return requestData.GetData(config)
}

const AlterRepairRecordOnTheWay = (data) => {
  const config = {
    method: "get",
    url: "/RepairRecordList/UpdateRepairRecord",
    params: {
      repairCode: data.indexRecord,
      machineTpye: data.machineTpye,
      breakCause: data.breakCause,
      password: data.password,
      appendRemark: data.appendRemark,
    }
  };
  return requestData.GetData(config)
}

const checkModify = (storageId) => {
  const config = {
    method: "get",
    url: "/MachineStorageList/CheckCommodityUse",
    params: {
      storageId: storageId
    }
  };
  return requestData.GetData(config)
}

const getVirtualInfo = (commId) => {
  const config = {
    method: "get",
    url: "/CommodityMangeTable/GetCommodityVirtual",
    params: {
      commId: commId
    }
  };
  return requestData.GetData(config)
}

const checkTobePaid = (docId) => {
  const config = {
    method: "get",
    url: "/MoneyToBePayImmediateList/CheckMoneyToBePaid",
    params: {
      docId: docId
    }
  };
  return requestData.GetData(config)
}

const checkIsXl = (companyId) => {
  const config = {
    method: "get",
    url: "/CompanyLayerMange/CheckIsXl",
    params:{
      companyId:companyId
    }
  };
  return requestData.GetData(config)
}


const getDiyBarcodeById = (commId) => {
  const config = {
    method: "get",
    url: "/DiyCommodityMangeTable/GetBarcodeById",
    params:{
      commId:commId
    }
  };
  return requestData.GetData(config)
}

export {
  washBodyCopy,getVirtualInfo,checkTobePaid,
  addAccountsChangeOnTheWay,checkIsXl,
  AlterRepairRecordOnTheWay,getDiyBarcodeById,
  getCustomerSource,
  deleteAccountsChangeOnTheWayList,
  checkModify,
  getObTracingInfo,
  initializeOutSourceAppendInfo,
  updatePoBodyRemarkReal,
  getFrBody,
  getOutSourceAppendInfo,
  deleteOneSoLineTrue,
  addQuotationForFrList,
  getCommodityBodyById,
  copyOrder,
  confirmQuotationReal,
  getAllWarningList,
  checkPoLineBeforeEdit,
  deleteOnePoLineTrue,
  updatePoLineUnitPriceCheckReal,
  getCreditValue,
  checkSetUpCondition,
  fakeDeleteDocumentCenter,
  updateDocCenter,
  updatePoLineUnitPriceReal,
  updateStaffCredit,
  getBodyInstance,
  updateStaffIdCheck,
  checkSoLineDelete,
  getCounterPartInven,
  getToBeInStockQty,
  updateToBeShippedRecord,
  updateStaffId,
  updateHeadRemarkReal,
  updateRoSaleAttrReal,
  getAllVmiByCommodityId,
  getMiListUnCheck,
  updateDiscoutCheckReal,
  updateSoBodyRemarkReal,
  getQuoList,
  collectMiMoney,
  updateSoLineUnitPriceCheckReal,
  updateSoLineUnitPriceReal,
  getRetailPriceById,
  dbCheckMiDefinite,
  checkRoLineDelete,
  deleteOneRoLineTrue,deleteOneRbLineTrue,
  combineProfit,
  updateRoBodyRemarkReal,
  updateRoLineUnitPriceCheckReal,
  getDetailProfitList,
  updateRoSaleAttrCheckReal,
  updateRoLineUnitPriceReal,
  checkTtCondi,
  updateCustomerSourceReal,
  getFrAddress,
  checkSalarySettle,
  getFriendStockList,
  addProfit,
  addPlatformReal,
  addPlatformForGroupReal,
  getFrStaff,
  getDocDetail,
  alterOnTheWayForSaleCommodityId,
  sendBenchMarkChange,
  getShippedPrice,
  getMachineTypeList,
  getAllCommByCommodityId,
  getComNameById,
  updateRecycleOnTheWayDoneList,
  getAllCommPurchasedToday,
  getSourceFrcode,
  getAllVmiByCommArr,
  openOnTheWayList,
  getShipTargetInfo,
  getOnTheWayQty,
  checkDocConditionPormise,
  getVmiCost,
  deleteToBeShippedRecord,
  getRelatedDocNo,
  closeRepairPromise,
  updateCustomerSourceStaticPromise,
  openIoList,
  addSourceStatisticsPromise,
  openStaffKpiStatisticsPromise,
  openRbList,
  updateProfitPerByDocId,
  updateProfitPerBySourDocId,
  deleteSalesOnTheWay,
  deleteRecycleOnTheWay,
  openPrList,
  getStaffId,
  updateRecyclePrice,
  openEoList,
  openRrList,
  finishDocHeadFroBodyCopy,
  addMoneyToBePaid,
  updateBpAuth,
  addMachineQueryList,
  addStOnTheWay,
  getUserInfo,
  updateLoginInfo,
  finishDocHead,
  refuseStOnTheWay,
  inStockStOnTheWay,
  getBookInfo,
  getUserAuto,
  updateWarehouseAuth,
  updateSeeAllWarehouse,
  checkBookOrNot,
  updateSeeAllBp,
  updateCostVisibility,
  washDocHead,
  updateMainManuAuth,
  updateViewAuth,
  updateButtonAuth,
  deleteBookApply,
  getTobeCopyList,
  sendChangePw,
  getPartBIdBydocId,
  sendRejectApply,
  updateWarning,
  getCommodityIdsByObId,
  copyArrayDoc,
  getStaffIdByMachineId,
  getLoginData,
  updateSaleInfoForMachineQuery,
  refreshLoginConfigData,
  openMbList,
  deleteMachineQuery,
  checkDocBody,
  rejectAllQuery,
  repairUpdateStaff,
  getDocNo,
  updateOutSourceCompany,
  checkDocHead,
  deleteRepairRecord,
  deleteDocCenter,
  repairOk,
  inspectOk,
  openDocCenter,
  outSourceReceive,
  outSourceBackConfirm,
  openPoList,
  sendNoticeCustomer,
  addRecycleOnTheWay,
  checkDocCondition,
  getNameList,
  openSoList,
  openRoList,
  fillContentWithRes,
  checkCommIds,
  openIsList,
  openOoList,
  openStList,
  checkInStockId,
  clearTableContent,
  openMiList,
  openMoList,
  transferToCode,
  getCount,
  openFrList,
  getVmiCid,
  getArrAttriByCoId,
  transferToName,
  updateRetailConfig,
  openEdList,
  openErList,
  openGdList,
  openGrList,
  closeRepair,
  addSourceStatistics,
  updateCustomerSourceStatic,
  openStaffKpiStatistics,
  changeToViewCode,
  openObList,
  fillDefaultValue,
  addObTracingObj,
  openAsList,
  openMpList,
  getDocType,
  addRemindTime,
  closeObChasing,
  PostMachine,
  unPostMachine,
  checkTtCompany,
  openTtList,
  ttInstock,
  deleteAcceptOnTheWay,
  deleteOnTheWay,
  rejectPurchaseApply,
  checkTtInWarehouse,
  checkSoInWarehouse,
  updateInwarehouseId,
  updateTtOut,
  updateSoOut,
  rejectSaleApply,
  updatePoIn,
  deleteSalesAcceptOnTheWay,
  openMsList,
  getDiscountValue,
  getVmiCostByAttr,
  getPlatReceive,
  getCompanyName,checkRbLineDelete,updateWarningAll
}