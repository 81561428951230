import axios from 'axios';
import store from "@/store/index.js"
import router from "@/router/index.js";

export default {
    GetData(config) {
        const instance = axios.create({
            // baseURL: process.env.NODE_ENV === 'development' ? 'https://localhost:44315/abc' : 'http://124.222.190.89:80/abc',
            baseURL: process.env.NODE_ENV === 'development' ? 'https://localhost:44315/abc' : 'https://www.abcyunlian.com/abc',
            timeout: 2000000,
        })

        instance.interceptors.request.use(config => {
            store.commit('turnOnLoading')
            if (config.url.indexOf('Login') > 1 || config.url.indexOf('Register') > -1) {

            } else {
                config.headers.RdsCode = store.state.loginInitializeData.rdsCode || ''
            }
            return config;
        }, err => {
            store.commit('turnOffLoading')
        })

        instance.interceptors.response.use(res => {
            store.commit('turnOffLoading')
            if (res.data == "refuse") {
                alert('登陆账号超时或在其他地方登录，请重新登陆');
                router.push({
                    path: "/login"
                });
            }
            return res.data;
        }, err => {
            store.commit('turnOffLoading')
        })
        return instance(config)
    },

    GetDataLong(config) {
        const instance = axios.create({
            // baseURL: process.env.NODE_ENV === 'development' ? 'https://localhost:44315/abc' : 'http://124.222.190.89:80/abc',
            baseURL: process.env.NODE_ENV === 'development' ? 'https://localhost:44315/abc' : 'https://www.abcyunlian.com/abc',
            timeout: 5000000,
        })

        instance.interceptors.request.use(config => {
            store.commit('turnOnLoading')
            if (config.url.indexOf('Login') > -1 || config.url.indexOf('Register') > -1) {} else {
                config.headers.RdsCode = store.state.loginInitializeData.rdsCode || ''
                
            }
            return config;
        }, err => {
            store.commit('turnOffLoading')
        })

        instance.interceptors.response.use(res => {
            store.commit('turnOffLoading')
            if (res.data == "refuse") {
                alert('登陆账号超时或在其他地方登录，请重新登陆');
                router.push({
                    path: "/login"
                });
            }
            return res.data;
        }, err => {
            store.commit('turnOffLoading')
        })
        return instance(config)
    },

    GetCache(config) {
        const instance = axios.create({
            // baseURL: process.env.NODE_ENV === 'development' ? 'https://localhost:44315/abc' : 'http://124.222.190.89:80/abc',
            baseURL: process.env.NODE_ENV === 'development' ? 'https://localhost:44315/abc' : 'https://www.abcyunlian.com/abc',
            timeout: 100000,
        })

        instance.interceptors.request.use(config => {
            if (config.url.indexOf('Login') > 1 || config.url.indexOf('Register') > -1) {

            } else {
                config.headers.RdsCode = store.state.loginInitializeData.rdsCode || ''
            }
            return config;
        }, err => {
        })

        instance.interceptors.response.use(res => {
            if (res.data == "refuse") {

                alert('登陆账号超时或在其他地方登录，请重新登陆');
                router.push({
                    path: "/login"
                });
            }
            return res.data;
        }, err => {
        })
        return instance(config)
    }
}