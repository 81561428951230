import {
  createRouter,
  createWebHistory
} from 'vue-router'
// import Home from '../views/Home.vue'


const routes = [

  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '维修管理软件'
    },
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/register',
    meta: {
      title: '注册'
    },
    component: () => import('@/views/Register.vue')
  },
  {
    path: '/main.abc',
    meta: {
      title: 'ABC云联'
    },
    component: () => import('@/views/MainPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: 'history',
  base: 'xl',
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
})

export default router