import requestData from "@/hooks/requestData.js";
import store from '@/store';
import router from "@/router/index.js";
import {
  leftJoinTable,
  copyObj,
  copyArrayOnly,
  copyArray
} from '@/hooks/tools';

const getValiList = function (tableName = '', method = 'add') {
  let search = {
    tableNameForVali: tableName,
    ValidateMethod: method
  }
  let config = {
    method: "post",
    url: "/ListForValidation/GetListByMulSearch",
    data: search,
  }
  return GetDataLong.GetDataLong(config)
}

const loginInitializeCheck = function (upLoadData) {
  // if (!upLoadData.ProductTypeName) {
  //   alert('产品类型未选择');
  //   return false;
  // }
  if (!upLoadData.CompanyNameAbbre) {
    alert('公司名称未填写');
    return false;
  }
  if (!upLoadData.UserName) {
    alert('用户名未填写');
    return false;
  }
  if (!upLoadData.Password) {
    alert('密码未填写');
    return false;
  }
  let config = {};
  config.method = 'post';
  config.url = '/Login/Add';
  config.data = upLoadData;
  return config;
};

const loginInitialize = function (config, saveUserJudge) {
  requestData
    .GetDataLong(config)
    .then((res) => {
      if (res.allowLogin == false) {
        
        alert(res.errMess);
        return false;
      } else {
        if (saveUserJudge) {
          saveUser(config.data)
        }
        transferLoginData(res)
        
        router.push({
          path: "/main.abc"
        });
        
      }
    })
    .catch((err) => {
      
      alert(err.message);
    });
}

const refreshLoginDate = function (config, funcAfterRefresh) {
  
  requestData
    .GetDataLong(config)
    .then((res) => {
      if (res.allowLogin == false) {
        
        alert(res.errMess);
        return false;
      } else {
        transferLoginData(res)
        funcAfterRefresh()
        
      }
    })
    .catch((err) => {
      
      alert(err.message);
    });
}

const transferLoginData = function (res) {
  res.viewListForSearch = []
  res.mainManuForShow = sortToManu(res.authList,res.viewListForSearch)
  res.positiomList = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1', 'L1', 'M1', 'N1', 'O1', 'P1', 'Q1', 'R1', 'S1', 'T1', 'U1', 'V1', 'W1', 'X1', 'Y1', 'Z1', 'AA1', 'AB1', 'AC1', 'AD1', 'AE1', 'AF1', 'AG1', 'AH1', 'AI1', 'AJ1']
  addFilter(res)
  if (store.state.loginInitializeData) {
    res.ActiveWindow = copyArrayOnly(store.state.loginInitializeData.ActiveWindow)
    res.ShowWindow = copyArrayOnly(store.state.loginInitializeData.ShowWindow)
  } else {
    res.ActiveWindow = [res.defaultWebManage.viewCode]
    res.ShowWindow = [res.defaultWebManage.viewCode]
  }
  res.authList.buttonVisibleManages = leftJoinTable(res.authList.buttonVisibleManages,res.authList.viewButtonLists,'buttonCode')

  store.commit("SaveDatas", res);
}

const transferLoginDataOnly = function (res) {
  res.viewListForSearch = []
  res.mainManuForShow = sortToManu(res.authList,res.viewListForSearch)
  res.positiomList = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1', 'L1', 'M1', 'N1', 'O1', 'P1', 'Q1', 'R1', 'S1', 'T1', 'U1', 'V1', 'W1', 'X1', 'Y1', 'Z1', 'AA1', 'AB1', 'AC1', 'AD1', 'AE1', 'AF1', 'AG1', 'AH1', 'AI1', 'AJ1']
  addFilter(res)
  store.commit("SaveDatasOnly", res);
}

const addFilter = function (res) {
  res.filterList = {};
  for (let item of res.filterManage) {
    if(item.beforeTransfer == 'true'){
      item.beforeTransfer = true
    }
    if(item.beforeTransfer == 'false'){
      item.beforeTransfer = false
    }
    if (res.filterList[item.filterFieldsName]) {
      res.filterList[item.filterFieldsName].push(item)
    } else {
      res.filterList[item.filterFieldsName] = []
      res.filterList[item.filterFieldsName].push(item)
    }
  }
  res.filterList.SystemStatusId = shuffleToFilter(res.systemStatus, 'systemStatusId', 'systemStatusName')
  res.filterList.ManagedCompanys = shuffleToFilter(res.managedCompanys, 'companyId', 'companyNameAbbre')
  res.filterList.ManagedWarehouses = shuffleToFilter(res.managedWarehouses, 'wareHouseId', 'wareHouseName')
  res.filterList.GroupCompanys = shuffleToFilter(res.groupCompanys, 'companyId', 'companyNameAbbre')
  res.filterList.GroupStaffs = shuffleToFilter(res.groupStaffs, 'staffId', 'staffName')
  res.filterList.WareHouseId = shuffleToFilter(res.authList.wareHouseLists, 'wareHouseId', 'wareHouseName')
  res.filterList.DepartmentId = shuffleToFilter(res.authList.departmentLists, 'departmentId', 'departmentName')
  res.filterList.WareHouseIdOut = shuffleToFilter(res.authList.wareHouseListsOut, 'wareHouseId', 'wareHouseName')
  res.filterList.WareHouseIdIn = shuffleToFilter(res.authList.wareHouseListsIn, 'wareHouseId', 'wareHouseName')
  res.filterList.allWarehousePossibles = shuffleToFilter(res.authList.allWarehousePossibles, 'wareHouseId', 'wareHouseName')
  res.filterList.OutWareHouseId = res.filterList.WareHouseId
  res.filterList.InWareHouseId = res.filterList.WareHouseId
  res.filterList.BusinessPartnerId = shuffleToFilter(res.authList.businessPartnerLists, 'partBCompanyId', 'partBCompanyAbbre')
  res.filterList.PartBCompanyId = shuffleToFilter(res.authList.businessPartnerLists, 'partBCompanyId', 'partBCompanyAbbre')
  res.filterList.PartACompanyId = res.filterList.PartBCompanyId
  res.filterList.OutSourceCompanyId = shuffleToFilter(res.authList.businessPartnerLists, 'partBCompanyId', 'partBCompanyAbbre')
  res.filterList.StaffId = shuffleToFilter(res.authList.staffLists, 'staffId', 'staffName')
  res.filterList.PlatFormList = shuffleToFilter(res.authList.platformLists, 'companyId', 'companyNameAbbre')
  res.filterList.MachineClass = shuffleToFilter(res.authList.commodityFirstClasss, 'commodityFirstClassNo', 'commodityFirstClassName')
  res.filterList.CommodityFirstClassNo = res.filterList.MachineClass
  res.filterList.CapitalAccountId = shuffleToFilter(res.authList.capitalAccountLists, 'capitalAccountId', 'capitalAccountName')
  res.filterList.DocumentTypeId = shuffleToFilter(res.documentTypeManage, 'documentTitle', 'documentName')
  res.filterList.OwedCompanyId = shuffleToFilter(res.authList.businessPartnerLists, 'partBCompanyId', 'partBCompanyAbbre')
  res.filterList.DepartmentId = shuffleToFilter(res.authList.departmentLists, 'departmentId', 'departmentName')
  res.filterList.UserId = shuffleToFilter(res.authList.rdsUserss, 'userId', 'userName')
  res.filterList.SolvedUserid = res.filterList.UserId
  res.filterList.SalesAttributeId = shuffleToFilter(res.authList.salesAttributesManages, 'salesAttributeId', 'salesAttributeName')
  res.filterList.SalesAttributeNo = res.filterList.SalesAttributeId
  res.filterList.SolvedSalesAttributeNo = res.filterList.SalesAttributeId
  res.filterList.DocumentTypeName = shuffleToFilter(res.authList.documentTypeManages, 'documentTypeId', 'documentName')
  res.filterList.StartType = shuffleToFilter(res.authList.onTheWayTypeLists, 'startTypeCode', 'startTypeName')
  res.filterList.BrandId = shuffleToFilter(res.authList.brandLists, 'brandId', 'brandName')
  res.filterList.CustomerSourceId = shuffleToFilter(res.authList.customerSourceLists, 'customerSourceId', 'customerSourceName')
  res.filterList.AccountingSubjectId = shuffleToFilter(res.authList.accountingSubjectsManages, 'accountingSubjectId', 'accountingSubjectName')

  res.filterList.OwedCompanyId.unshift({
    beforeTransfer: parseInt(res.companyId),
    afterTransfer: res.companyNameAbbre
  })
  for (let i = 0; i < res.filterList.OwedCompanyId.length; i++) {
    if (res.filterList.OwedCompanyId[i].beforeTransfer >= 20000001) {
      res.filterList.OwedCompanyId.splice(i, 1);
      i--
    }
  }
}

const shuffleToFilter = function (arr, fromName, toName) {
  arr.sort(function (a, b) { return b?.weight - a?.weight })
  let arrTarget = [];
  for (let item of arr) {
    let newObj = {}
    newObj['beforeTransfer'] = item[fromName]
    newObj['afterTransfer'] = item[toName]
    arrTarget.push(newObj)
  }
  return arrTarget;
}


const registerUser = function (data, sendRegiData) {
  if (data.CompanyNameAbbre == '') {
    
    alert('公司名称不能为空');
    return false;
  }
  if (data.UserName == '') {
    
    alert('用户名不能为空');
    return false;
  }
  if (data.Password == '') {
    
    alert('密码不能为空');
    return false;
  }
  // if (data.RechargeCode == '') {
    
  //   alert('验证码不能为空');
  //   return false;
  // }
  if (data.Password != data.PasswordConfirm) {
    
    alert('2次密码不一致');
    return false;
  }

  if (data.CompanyNameAbbre.length > 10 && data.CompanyNameAbbre.length < 2) {
    
    alert('公司简称需要在2到10个字之间');
    return false;
  }
  if (data.UserName.length > 20 && data.UserName.length < 2) {
    
    alert('用户名需要在2到20个字符之间');
    return false;
  }
  if (data.Password.length > 20 && data.Password.length < 5) {
    
    alert('密码需要在5到20个字符之间');
    return false;
  }
  // if (data.Password != data.PasswordConfirm) {
    
  //   alert('2次密码不一致');
  //   return false;
  // }

  let config = {
    method: "post",
    url: "/Register/Add",
    data: data,
  };
  requestData
    .GetDataLong(config)
    .then(res => {
      if (res.indexOf('注册成功') > -1) {
        alert(res)
        sendRegiData()
        router.push({
          path: '/login'
        })
      } else {
        
        alert(res)
      }
    }).catch(err => {
      
      alert(err);
    })
}

const saveUser = function (userInfo) {

  const storage = window.localStorage;
  let userList = JSON.parse(storage.getItem('abcUsers'));
  if (!userList) {
    let newUserList = [];
    newUserList.push(userInfo)
    storage.setItem('abcUsers', JSON.stringify(newUserList))
    return;
  }
  for (let index in userList) {
    if (userList[index].CompanyNameAbbre == userInfo.CompanyNameAbbre &&
      userList[index].UserName == userInfo.UserName) {
      if (userList[index].Password != userInfo.Password) {
        if (confirm('是否更新用户登录信息')) {
          userList.splice(index, 1);
          userList.unshift(userInfo)
          storage.setItem('abcUsers', JSON.stringify(userList))
          return;
        }
      } else {
        let b = userList[index]
        userList.splice(index, 1);
        userList.unshift(b)
        storage.setItem('abcUsers', JSON.stringify(userList))
        return
      }
    }
  }
  if (userList.length > 4) {
    userList.pop();
    userList.unshift(userInfo)
  }
  if (userList.length <= 4) {
    userList.unshift(userInfo)
  }
  storage.setItem('abcUsers', JSON.stringify(userList))
}

const getUserInfo = function () {
  const storage = window.localStorage;
  const userList = JSON.parse(storage.getItem('abcUsers'));
  return userList
}

const sortToManu = function (lists,viewListForSearch) {

  lists.mainManuManages = lists.mainManuManages.filter(x=>x.visibleOrNot == true)
  lists.viewVisibleManages = lists.viewVisibleManages.filter(x=>x.visibleOrNot == true)


  let manuList = leftJoinTable(lists.mainManuManages, lists.mainManuLists, 'mainManuCode')
  let viewList = leftJoinTable(lists.viewVisibleManages, lists.viewLists, 'viewCode')
  let viewListNew = leftJoinTable(viewList, lists.viewLists, 'viewCode')

  for (let item of viewListNew) {
      let objNew = {};
      for (let vl in item) {
          objNew[vl] = item[vl]
      }
      viewListForSearch.push(objNew)
  }
  
  for (let a of manuList) {
    let c = [];
    for (let b of viewListNew) {
      if (b.mainManuCode == a.mainManuCode) {
        let d = copyObj(b)
        c.push(d)
      }
    }
    a.viewLists = c
  }
  for (let item of manuList) {
    if (item.viewLists.length) {
      for (let i = 0; i < item.viewLists.length; i++) {
        if (!item.viewLists[i].seeInMainMenu) {
          item.viewLists.splice(i, 1);
          i--
        }
      }
    }
  }
  let length1 = manuList.length
  for(let i = length1 - 1;i>=0;i--){
    if(manuList[i].viewLists.length == 0){
      manuList.splice(i,1)
    }
  }
  return manuList;
}

export {
  loginInitializeCheck,
  loginInitialize,
  getValiList,
  saveUser,
  getUserInfo,
  registerUser,
  refreshLoginDate,
  transferLoginData,
  transferLoginDataOnly,
  shuffleToFilter
}