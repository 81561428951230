import store from '@/store';
import requestData from "@/hooks/requestData.js";
import {
    transferToName
} from './OpenOrder';
//把日期变成年月日格式
const ChangeYMD = function (oldDate) {
    let da = new Date(oldDate);
    let year = da.getFullYear() + '';
    let month = da.getMonth() + 1 + '';
    let date = da.getDate() + '';
    if (month < 10) {
        month = '0' + month
    }
    if (date < 10) {
        date = '0' + date
    }
    return [year, month, date].join('-');
};

const ChangeYMDTime = function (oldDate) {
    function add(m) {
        return m < 10 ? '0' + m : m
    }
    //shijianchuo是整数，否则要parseInt转换
    var time = new Date(oldDate);
    var y = time.getFullYear();
    var m = time.getMonth() + 1;
    var d = time.getDate();
    var h = time.getHours();
    var mm = time.getMinutes();
    var s = time.getSeconds();
    return y + '-' + add(m) + '-' + add(d) + ' ' + add(h) + ':' + add(mm) + ':' + add(s);
};
//是字母大写
const ChangeFirToUp = function (str) {
    return str.substring(0, 1).toUpperCase() + str.substring(1, str.length)
};
//首字母小写
const ChangeFirToLo = function (str) {
    return str.substring(0, 1).toLowerCase() + str.substring(1, str.length)
};
//延迟器
const delay = function (interval = 0) {
    return new Promise(resolve => {
        let timer = setTimeout(_ => {
            clearTimeout(timer);
            resolve();
        }, interval);
    })
};
//读文件方法
const readFile = function (file) {
    return new Promise(resolve => {
        let reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = ev => {
            resolve(ev.target.result)
        };
    });
};

const copyArray = function (objArr) {
    let newObjArr = [];
    if (!objArr) {
        return newObjArr;
    }
    for (let item of objArr) {
        let objNew = {};
        for (let vl in item) {
            if (vl == 'SalesAttributeNoArr') {
                objNew[vl] = copyArray(item[vl])
            } else {
                objNew[vl] = item[vl]
            }
        }
        newObjArr.push(objNew)
    }
    return newObjArr;
};



const copyObj = function (obj) {
    let newObj = {};
    for (let b in obj) {
        newObj[b] = obj[b]
    }
    return newObj;
};

const checkIsXlTranction = function (partA, partB) {

    let part1Name = transferToName(partA, 'OwedCompanyId')
    let part2Name = transferToName(partB, 'PartBCompanyId')

    let b = true

    if (part1Name.indexOf('小懒') <= -1 && part1Name.indexOf('青屠') <= -1) {
        b = false
    }

    if (part2Name.indexOf('小懒') <= -1 && part2Name.indexOf('青屠') <= -1) {
        b = false
    }

    return b;
}

const leftJoinTable = function (targetArr, joinArr, joinFields) {
    let targetArrCopy = copyArray(targetArr)
    for (let a of targetArrCopy) {
        for (let b of joinArr) {
            if (a[joinFields] == b[joinFields]) {
                for (let key in b) {
                    if (key != joinFields) {
                        a[key] = b[key]
                    }
                }
            }
        }
    }
    return targetArrCopy;
};

const dataFilter = function (targetArr, filterArr, deleteKey) {
    for (let key in filterArr) {
        if (filterArr[key].Filter) {
            for (let item of targetArr) {
                for (let key2 in item) {
                    if (ChangeFirToLo(key2) == ChangeFirToLo(deleteKey)) {
                        continue
                    }
                    if (ChangeFirToLo(key2) == ChangeFirToLo(key)) {
                        for (let item2 of filterArr[key].Filter) {
                            if (item[key2].toString() == item2.beforeTransfer.toString()) {
                                item[key2] = item2.afterTransfer
                            }
                        }
                    }
                    if(item[key2] == '0' && ChangeFirToLo(key2) == 'userId'){
                        item[key2] = ''
                    }
                }
            }
        }
    }
};

const dataFilterConverse = function (targetArr, filterArr) {
    for (let key in filterArr) {
        if (filterArr[key].Filter) {
            for (let key2 in targetArr) {
                if (ChangeFirToLo(key2) == ChangeFirToLo(key)) {
                    for (let item2 of filterArr[key].Filter) {
                        if (targetArr[key2] == item2.afterTransfer) {
                            targetArr[key2] = item2.beforeTransfer
                        }
                    }
                }
            }
        }
    }
};

const fillSonContent = function (Content, originList, method, defaultv) {
    for (let key in originList) {
        if (originList[key][method + 'Trans'] == true) {
            Content[key] = originList[key][defaultv]
        }
    }
};

const clearObj = function (obj) {
    for (let key in obj) {
        delete obj[key];
    }
}

const upperObjKey = function (obj) {
    for (let key in obj) {
        obj[ChangeFirToUp(key)] = obj[key];
    }
    return obj;
}
const lowerObjKey = function (obj) {
    for (let key in obj) {
        obj[ChangeFirToLo(key)] = obj[key];
    }
    return obj;
}

const uniqueArrByKey = function (arr, key) {
    const res = new Map();
    return arr.filter(obj => !res.has(obj[key]) && res.set(obj[key], 1))
}

const changeArrContentToDate = function (arr, tableName) {
    if (tableName == 'InventoryChangeList' ||
        tableName == 'AccountsChangeList' ||
        tableName == 'DocumentCenter' ||
        tableName == 'CapitalAccountBlanceChangeList' ||
        tableName == 'MoneyToBePayImmediateList' ||
        tableName == 'ObTracingList' ||
        tableName == 'RepairRecordList' ||
        tableName == 'OnTheWayListForSale' ||
        tableName == 'RecyleOnTheWayDoneList' ||
        tableName == 'FrList' ||
        tableName == 'AccountsChangeOnTheWayList' ||
        tableName == 'ToBeShippedSolve') {
        for (let obj of arr) {
            for (let key in obj) {
                if (key == 'validateMethod') {
                    continue;
                }
                if (key.indexOf('date') > -1 || key.indexOf('Date') > -1) {
                    if(obj[key]){
                        obj[key] = ChangeYMDTime(obj[key])
                        if (obj[key].substring(0, 4) < 2000) {
                            obj[key] = ''
                        }
                    }

                }
            }
        }
        return;
    } else {
        for (let obj of arr) {
            for (let key in obj) {
                if (key == 'validateMethod') {
                    continue;
                }
                if (key.indexOf('date') > -1 || key.indexOf('Date') > -1) {
                    if(obj[key]){
                        obj[key] = ChangeYMD(obj[key])
                        if (obj[key].substring(0, 4) < 2000) {
                            obj[key] = ''
                        }
                    }

                }
            }
        }
    }

}

const copyArrayOnly = function (arr) {
    let arrNew = []
    for (let item of arr) {
        arrNew.push(item)
    }
    return arrNew;
}


const toDecimal2 = function (x) {
    var f = parseFloat(x);
    if (isNaN(f)) {
        return false;
    }
    var f = Math.round(x * 100) / 100;
    var s = f.toString();
    var rs = s.indexOf('.');
    if (rs < 0) {
        rs = s.length;
        s += '.';
    }
    while (s.length <= rs + 2) {
        s += '0';
    }
    return s;
}

const insertSearchOrigin = (e, searchResult) => {
    let inputValue;
    if(e?.target){
        inputValue = e.target.value;
    }
    else{
        inputValue = e
    }

    let inputValueLow = inputValue.toLowerCase()
    if (inputValue == '') {
        searchResult.splice(0);
        return false;
    }
    let config = {
        method: "get",
        url: "/CommodityMangeTable/Search",
        params: {
            str: inputValue
        }
    };
    requestData.GetCache(config).then(res => {
        searchResult.splice(0)
        let selfCommodityCount = 0
        for (let a of res) {
            //判断型号
            let modelNum = 0;
            let modelCheck = 0;

            if (a.model?.split) {
                let models = a.model.split("|")
                if (models.length > 0 && models[0] != '') {
                    modelCheck = 1;
                }
                for (let model of models) {
                    if (model == '') {
                        continue;
                    }
                    if (inputValueLow.indexOf(model.toLowerCase()) > -1) {
                        modelNum = 1;
                    }
                }
            };
            //判断通用
            let applyToNum = 0;
            let applyCheck = 0;
            if (a.applyTo ?.split) {
                let applyTos = a.applyTo.split("|")
                if (applyTos.length > 0 && applyTos[0] != '') {
                    applyCheck = 1;
                }
                for (let applyTo of applyTos) {

                    if (applyTo == '') {
                        continue;
                    }
                    if (inputValueLow.indexOf(applyTo.toLowerCase()) > -1) {
                        applyToNum = 1;
                    }
                }
            };
            //判断物料
            let materialNum = 0;
            let materialCheck = 0;
            if (a.material ?.split) {
                let materials = a.material.split("|")
                if (materials.length > 0 && materials[0] != '') {
                    materialCheck = 1;
                }
                for (let material of materials) {
                    if (material == '') {
                        continue;
                    }
                    if (inputValueLow.indexOf(material.toLowerCase()) > -1) {
                        materialNum = 1;
                    }
                }
            };
            //判断颜色
            let colorNum = 0;
            let colorCheck = 0;
            if (a.color ?.split) {
                let colors = a.color.split("|")
                if (colors.length > 0 && colors[0] != '') {
                    colorCheck = 1;
                }
                for (let color of colors) {
                    if (color == '') {
                        continue;
                    }
                    if (inputValueLow.indexOf(color.toLowerCase()) > -1) {
                        colorNum = 1;
                    }
                }
            };
            //判断机器版本
            let versionNum = 0;
            let versionCheck = 0;
            if (a.version ?.split) {
                let versions = a.version.split("|")
                if (versions.length > 0 && versions[0] != '') {
                    versionCheck = 1;
                }
                for (let version of versions) {
                    if (version == '') {
                        continue;
                    }
                    if (inputValueLow.indexOf(version.toLowerCase()) > -1) {
                        versionNum = 1;
                    }
                }
            };
            //判断物品属性
            let typeNum = 0;
            let typeCheck = 0;
            if (a.type ?.split) {
                let types = a.type.split("|")
                if (types.length > 0 && types[0] != '') {
                    typeCheck = 1;
                }
                for (let type of types) {
                    if (type == '') {
                        continue;
                    }
                    if (inputValueLow.indexOf(type.toLowerCase()) > -1) {
                        typeNum = 1;
                    }
                }
            };
            //判断其他属性
            let otherNum = 0;
            let otherCheck = 0;
            if (a.other ?.split) {
                let others = a.other.split("|")
                if (others.length > 0 && others[0] != '') {
                    otherCheck = 1;
                }
                for (let other of others) {
                    if (other == '') {
                        continue;
                    }
                    if (inputValueLow.indexOf(other.toLowerCase()) > -1) {
                        otherNum = 1;
                    }
                }
            };

            let score = Math.round((modelNum * 30 + materialNum * 25 + colorNum * 15 + versionNum * 12 + typeNum * 12 + otherNum * 6) / (modelCheck * 30 + materialCheck * 25 + colorCheck * 15 + versionCheck * 12 + typeCheck * 12 + otherCheck * 6) * 100)
            let realscore = parseFloat(score)
            a.show = '匹配度' + score + '%';
            a.show1 = realscore
            if (applyToNum > 0 && materialNum > 0) {
                a.show = '可能通用'
                a.show1 = 101
            }
            if(a.commodityId.substring(0,3) == '999'){
                a.show1 = 102
                a.show = ''
                selfCommodityCount++
            }
            searchResult.push(a)
        }


        searchResult.sort(function (a, b) {
            return b.show1 - a.show1
        })

        let cutLength = 0;
        if (searchResult.length - (10 + selfCommodityCount) < 0) {
            cutLength = 0
        } else {
            cutLength = searchResult.length - (10 + selfCommodityCount)
        }
        if (cutLength > 0) {
            for (let i = 0; i < cutLength; i++) {
                searchResult.pop()
            }
        }
        setTimeout(() => {
            for (let c of searchResult) {
                // let judge = false;
                // for (let key of stockBalanceCache.keys()) {
                //     if (c.commodityId == key) {
                //         c.inventoryBalance = `库存:${stockBalanceCache.get(key)}`
                //         judge = true
                //     }
                // }
                // if (judge) {
                //     continue;
                // }
                let config1 = {
                    method: "get",
                    url: "/InventoryBalanceList/GetInvenQty",
                    params: {
                        id: c.commodityId
                    }
                };
                requestData.GetCache(config1).then(res => {
                    // stockBalanceCache.set(c.commodityId, res)
                    c.inventoryBalance = `可销库存:${res}`
                })
            }
        }, 0);
    })
}

function isRealNum(val) {
    // isNaN()函数 把空串 空格 以及NUll 按照0来处理 所以先去除
    if (val === "" || val == null) {
        return false;
    }
    val = Number(val)
    if (!isNaN(val)) {
        return true;
    } else {
        return false;
    }
}

function getInventory(commodityId) {
    const config = {
        method: "get",
        url: '/InventoryBalanceList/GetInvenQty',
        params: {
            id: commodityId
        }
    };
    return requestData.GetCache(config)
}

function getSalePrice(commodityId, partBCompanyId) {
    const config = {
        method: "get",
        url: "/CommodityMangeTable/GetSalePirceById",
        params: {
            id: commodityId,
            bpId: partBCompanyId
        }
    };
    return requestData.GetCache(config)
}

function getBuyPrice(commodityId, partBCompanyId) {
    const config = {
        method: "get",
        url: "/CommodityMangeTable/GetBuyPriceById",
        params: {
            id: commodityId,
            bpId: partBCompanyId
        }
    };
    return requestData.GetCache(config)
}

function copyMap(originMap) {
    let obj = Object.create(null);
    for (let [k, v] of originMap) {
        obj[k] = v;
    }
    obj = JSON.stringify(obj);
    obj = JSON.parse(obj);
    let tmpMap = new Map();
    for (let k of Object.keys(obj)) {
        tmpMap.set(k, obj[k]);
    }
    return tmpMap;
}

function GetNumberOfDays(date1, date2) { //获得天数
    //date1：开始日期，date2结束日期
    var a1 = Date.parse(new Date(date1));
    var a2 = Date.parse(new Date(date2));
    var day = parseInt((a2 - a1) / (1000 * 60 * 60 * 24)); //核心：时间戳相减，然后除以天数
    return day
};

function tickOutVirtualCompany(arr) {
    let i = arr.length
    for (let j = i - 1; j >= 0; j--) {
        if (+arr[j].beforeTransfer > 20000000) {
            arr.splice(j, 1)
        }
    }
}

function getDateTime(type) {
    var date = new Date();
    var hengGang = "-";
    var maoHao = ":";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var curDate = date.getDate();
    var curHours = date.getHours();
    var curMinutes = date.getMinutes();
    var curSeconds = date.getSeconds();

    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (curDate >= 0 && curDate <= 9) {
        curDate = "0" + curDate;
    }
    if (curHours >= 0 && curHours <= 9) {
        curHours = "0" + curHours;
    }
    if (curMinutes >= 0 && curMinutes <= 9) {
        curMinutes = "0" + curMinutes;
    }
    if (curSeconds >= 0 && curSeconds <= 9) {
        curSeconds = "0" + curSeconds;
    }
    var currentdate = "";
    if (type == "year") {
        currentdate = year;
        return currentdate;
    } else if (type == "month") {
        currentdate = year + hengGang + month;
        return currentdate;
    } else {
        currentdate = year + hengGang + month + hengGang + curDate + " " + curHours + maoHao + curMinutes + maoHao + curSeconds;
        return currentdate;
    }
}

function getUUId() {
    var uuid = formatDateTime() + Math.random().toString(36).substring(2);

    function formatDateTime() {
        var date = new Date();
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        var h = date.getHours();
        var minute = date.getMinutes();
        var second = date.getSeconds();
        return y + m + d + h + minute + second;
    }

    return uuid;
}

function getFirstDayOfMonth() {
    var date=new Date();
    date.setDate(1);
    date.setHours(0);
    date.setSeconds(0);
    date.setMinutes(0);
    return date;
}

function getLastDayOfMonth() {
    var date=new Date();
    var currentMonth=date.getMonth();
    var nextMonth=++currentMonth;
    var nextMonthFirstDay=new Date(date.getFullYear(),nextMonth,1);
    var oneDay=1000*60*60*24;
    return new Date(nextMonthFirstDay-oneDay);
}


function changeDayToInt(date, type) {
    const y = date.getFullYear()
    let m = date.getMonth() + 1
    m = m < 10 ? ('0' + m) : m;
    let d = date.getDate()
    d = d < 10 ? ('0' + d) : d;
    if (type == 'day') {
        return parseInt('' + y + m + d)
    } else if (type == 'month') {
        return parseInt('' + y + m)
    } else {
        return parseInt('' + y)
    }
}


function addDate(date, days) {
    var d = new Date(date);
    d.setDate(d.getDate() + days);
    var m = d.getMonth() + 1;
    return new Date(d.getFullYear() + '/' + m + '/' + d.getDate());
}

function addMonth(date, months) {

    const i = new Date(date)

    const d = new Date(i.setMonth(i.getMonth() + months))

    return d;
}

function randomHex() {
    return `#${Math.floor(Math.random() * 0xffffff).toString(16).padEnd(6, "0")}`;
}

function getInventoryByWareHouse(commId, wareHouseId) {
    const config = {
        method: "get",
        url: '/InventoryBalanceList/GetInvenQtyByWareHouse',
        params: {
            commId: commId,
            wareHouseId: wareHouseId
        }
    };
    return requestData.GetCache(config)
}

function checkSameCompany(partAId, partBId) {
    const config = {
        method: "get",
        url: '/CompanyUserManage/CheckSameCompany',
        params: {
            partAId: partAId,
            partBId: partBId
        }
    };
    return requestData.GetCache(config)
}

function sortUpRemark(arr) {
    let returnWord = ''
    if (arr.length == 0) {
        return ''
    } else {
        for (let item of arr) {
            returnWord += `${item.commodityName}*${item.orderQty}\n`
        }
        return returnWord;
    }
}

function toTimeStamp(dateString) {
    // dateString例如:'2022-03-05'
    // 例如返回:1646611200000
    return new Date(dateString) - 0
}

function getDistanceDays(date1, date2) {
    // date1例如:'2022-03-05',date2例如:'2022-03-06'
    const date1_timeStamp = toTimeStamp(date1)
    const date2_timeStamp = toTimeStamp(date2)
    let max = '',
        min = ''
    if (date1_timeStamp > date2_timeStamp) {
        max = date1_timeStamp
        min = date2_timeStamp
    } else {
        max = date2_timeStamp
        min = date1_timeStamp
    }
    // 例如返回:'1'
    return (max - min) / (24 * 60 * 60 * 1000)
}

function dateData(property, bol) { //property是你需要排序传入的key,bol为true时是升序，false为降序
    return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        if (bol == 1) {
            // 升序
            return Date.parse(value1) - Date.parse(value2);
        } else {
            // 降序
            return Date.parse(value2) - Date.parse(value1)
        }

    }
}

function MiForMeiTuan(amount) {
    if (!isRealNum(amount)) {
        return 0;
    }
    amount = +amount
    if (amount <= 1.5) {
        return 0;
    }
    if (amount <= 30) {
        return +(amount - 1.5).toFixed(2)
    }
    return +(amount * 0.95).toFixed(2)
}

function MiReverseMeiTuan(amountAfter) {
    if (!isRealNum(amountAfter)) {
        return 0;
    }
    amountAfter = +amountAfter

    if (amountAfter <= 28.5) {
        return +(amountAfter + 1.5).toFixed(2)
    }
    return +(amountAfter / 0.95).toFixed(2)
}

function getRank(arr,sortFieldsName) {
    if(Array.isArray(arr)){

        if(arr.length < 1){
            return 1;
        }
        else{
            arr = arr.sort(function (a, b) { return b[sortFieldsName] - a[sortFieldsName] })

            const len1 = arr.length
            
            for(let k = 0;k < len1;k++){
                if(arr[k][sortFieldsName] <= 0){
                    return +k + 1;
                }
    
            }

            return +len1 + 1;

        }
    }
    else{
        return -1;
    }

    
}






export {
    ChangeYMD,
    ChangeYMDTime,
    dateData,getRank,
    MiForMeiTuan,MiReverseMeiTuan,
    checkSameCompany,
    getDateTime,
    sortUpRemark,
    getUUId,
    getDistanceDays,
    GetNumberOfDays,
    getInventory,
    getInventoryByWareHouse,
    tickOutVirtualCompany,
    getSalePrice,
    getBuyPrice,
    copyMap,
    ChangeFirToUp,
    ChangeFirToLo,
    delay,
    readFile,
    copyArray,
    copyObj,
    leftJoinTable,
    dataFilter,
    fillSonContent,
    dataFilterConverse,
    clearObj,
    upperObjKey,
    lowerObjKey,
    uniqueArrByKey,
    changeArrContentToDate,
    copyArrayOnly,
    toDecimal2,
    insertSearchOrigin,
    isRealNum,
    changeDayToInt,
    getFirstDayOfMonth,
    getLastDayOfMonth,
    addDate,
    addMonth,
    randomHex,
    checkIsXlTranction
}